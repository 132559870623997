import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon, ModalCloseIcon } from "../styled";

interface IRangeModel {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RangeModel: React.FC<IRangeModel> = ({
  open,
  setOpen,
}): React.ReactElement => {
  const redirectUrl = process.env.REACT_APP_HOME_SCREEN_REDIRECT_URL as string;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      PaperProps={{ style: { margin: "0px" } }}
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        },
      }}
    >
      <DialogActions
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <CloseIcon>
          <ModalCloseIcon
            data-testid="modal-close"
            onClick={() => setOpen(false)}
          />
        </CloseIcon>
      </DialogActions>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" color={"#494E9D"}>
            HEALTH SCORE RANGE
          </Typography>
          <Grid container py={1.5}>
            <Grid item xs={1}>
                <Box height={250} width={10} sx={{background: "linear-gradient(0deg, rgba(114,214,90,1) 0%, rgba(255,181,80,1) 36%, rgba(251,136,136,1) 100%)", borderRadius: 2}}></Box>
            </Grid>
            <Grid item xs={11} px={1} sx={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <Box>
                    <Typography variant="h4" color={"#494E9D"}>0-143: POOR</Typography>
                    <Typography>Your health score indicates areas that may need attention, take action now!</Typography>
                </Box>
                <Box>
                    <Typography variant="h4" color={"#494E9D"}>144-251: GOOD</Typography>
                    <Typography>While your health score is positive, it's crucial to maintain healthy habits.</Typography>
                </Box>
                <Box>
                    <Typography variant="h4" color={"#494E9D"}>252-360: EXCELLENT</Typography>
                    <Typography>Great score reflects healthy practices; continue maintaining your well-being.</Typography>
                </Box>
            </Grid>
          </Grid>
        </DialogContent>
    </Dialog>
  );
};

export default RangeModel;

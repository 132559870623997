import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ProgressState {
  progress: {
    [key: number]: number;
  };
}

interface UpdateProgress {
  question: number;
  journey: number;
  noofQuestions: number;
}

const initialState: ProgressState = {
  progress: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  },
};

const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    updateProgress: (state, action: PayloadAction<UpdateProgress>) => {
      state.progress[action.payload.journey-1] =
        (action.payload.question / action.payload.noofQuestions) * 100;
    },
    updateAllProgress: (state, action: PayloadAction<number>) => {
      for (let i = 0; i < action.payload; i++) {
        state.progress[i] = 100;
      }
    },
    resetProgress: () => initialState,
  },
});

export const { updateProgress, updateAllProgress, resetProgress } = progressSlice.actions;

export default progressSlice.reducer;

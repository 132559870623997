import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import ExitImage from "../../utils/images/ExitImg.png";
import Health360Image from "../../utils/images/Health360.png";
import { CloseIcon, ModalCloseIcon } from "../styled";

interface IExitModel {
  open: boolean;
  setOpen: (open: boolean) => void;
  exit: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExitModel: React.FC<IExitModel> = ({
  open,
  setOpen,
  exit,
}): React.ReactElement => {
  const redirectUrl = process.env.REACT_APP_HOME_SCREEN_REDIRECT_URL as string;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      PaperProps={{ style: { margin: "0px" } }}
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        },
      }}
    >
      <DialogActions
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <CloseIcon>
          <ModalCloseIcon
            data-testid="modal-close"
            onClick={() => setOpen(false)}
          />
        </CloseIcon>
      </DialogActions>
      {exit ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <img src={ExitImage} alt="Leaving Early"></img>
          </Box>
          <Typography variant="h4" color={"#494E9D"}>
            Leaving so early?
          </Typography>
          <Typography variant="h6" color={"#6F6F6F"} align="center">
            Dont worry, you can always come back and resume from where you left.
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <img src={Health360Image} alt="Leaving Early"></img>
          </Box>
          <Typography variant="h4" color={"#494E9D"}>
            Health 360
          </Typography>
          <Typography variant="h6" color={"#6F6F6F"} align="center">
            Health360, a dynamic health index, monitors individuals in real-time
            across five dimensions: Awareness, Lifestyle, Stats, Emotional
            Wellbeing, and Screening. It empowers better decisions for fostering
            healthier habits.
          </Typography>
        </DialogContent>
      )}
      {exit ? (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            sx={{ color: "#494E9D" }}
            onClick={() => setOpen(false)}
          >
            Continue
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{ backgroundColor: "#494E9D" }}
            onClick={() => (window.location.href = redirectUrl)}
          >
            End
          </Button>
        </DialogActions>
      ) : (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            sx={{ color: "#494E9D" }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ExitModel;

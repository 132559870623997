import React from "react";
import {
  Box,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Scrollbar from "../Scrollbar";
import { GoldCircle, GoldPlus } from "../../utils";
import { Recommendations, steps } from "../../services/CategoriesData";

interface IInsightsModel {
  open: boolean;
  setOpen: (open: boolean) => void;
  recommendations: Recommendations[];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InsightsModel: React.FC<IInsightsModel> = ({
  open,
  setOpen,
  recommendations,
}): React.ReactElement => {
  return (
    <Dialog
      open={open}
      fullScreen
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      PaperProps={{ style: { margin: "0px" } }}
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100vh",
        },
      }}
    >
      <DialogTitle
        sx={{
          background:
            "linear-gradient(90deg, rgba(233,255,252,1) 1%, rgba(220,223,241,1) 100%);",
          color: "#313131",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          height: "10vh",
        }}
      >
        <IconButton
          size="large"
          sx={{ color: "#494E9D" }}
          onClick={() => setOpen(false)}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Typography variant="h4">How can I improve my score?</Typography>
      </DialogTitle>
      <Scrollbar>
        <DialogContent sx={{ height: "88vh" }}>
          <Typography paddingTop={1} variant="h4">
            Recommendations ({recommendations.length})
          </Typography>
          {recommendations.map((recommendation) => (
            <Card
              key={recommendation._id}
              sx={{ border: "2px solid #E3E4F9", p: 2, my: 1, boxShadow: "none" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: 1,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4">{recommendation.title}</Typography>
                    {recommendation.score > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginRight: -2,
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 30,
                            height: 30,
                            borderRadius: "50%",
                            backgroundColor: "white",
                            border: "1px solid #72D65A",
                            marginRight: -1.5,
                          }}
                        >
                          <GoldCircle
                            style={{ position: "absolute" }}
                            width={20}
                            height={20}
                          />
                          <GoldPlus style={{ position: "absolute" }} />
                        </Box>
                        <Box
                          sx={{
                            width: 42,
                            height: 24,
                            backgroundColor: "#0096FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            p: 1,
                          }}
                        >
                          <Typography
                            color={"white"}
                            fontSize={11}
                            fontWeight={800}
                          >
                            +{recommendation.score}
                          </Typography>
                        </Box>
                      </Box>) : null}
                  </Box>
                  <Typography>{recommendation.description}</Typography>
                  <Box>
                    {recommendation.score > 0 ? (
                      <Typography fontWeight={800}>
                        {`Improves score by ${recommendation.score} points in:`}
                      </Typography>
                    ) : null}
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        paddingTop: 1,
                      }}
                    >
                      {recommendation.category_details.map((category) => (
                        <Chip
                          sx={{
                            backgroundColor:
                              steps.find(
                                (step) =>
                                  step.categoryId === category.category_key
                              )?.categorySecondaryColor ?? "#E0F2DC",
                            color:
                              steps.find(
                                (step) =>
                                  step.categoryId === category.category_key
                              )?.categoryPrimaryColor ?? "#72D65A",
                          }}
                          label={category.category_name}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Card>
          ))}
        </DialogContent>
      </Scrollbar>
    </Dialog>
  );
};

export default InsightsModel;

import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {
  Bridge,
  Demographics,
  Login,
  OnBoardingPage,
  Overview,
  Questionnaire,
  ScoreCard,
  SlideCarousel,
  SuccessScreen,
} from "../pages";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategories,
  setQuestionJourney,
  setQuestionNumber,
  setQuestions,
} from "../store/features/questions";
import {
  useGetAuthenticateQuery,
  useGetCategoriesQuery,
  useGetQuestionsQuery,
} from "../store/features/api";
import { Loader } from "../components";
import { updateAllProgress, updateProgress } from "../store/features/progress";
import { RootState } from "../store";

const AppRoutes: React.FC = (): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const {
    data: userData,
    isLoading: userLoading,
    refetch: authRefetch,
  } = useGetAuthenticateQuery({
    svaas_user_id: "",
    age: 0,
    gender: "",
  });
  const {
    data: categories,
    isLoading: categoriesLoading,
    refetch: categoryRefetch,
  } = useGetCategoriesQuery("");
  const {
    data: questions,
    isLoading,
    refetch: questionRefetch,
  } = useGetQuestionsQuery({
    categoryId: userData?.data?.survey_data?.current_category_id,
  });
  const questionNumber = useSelector(
    (state: RootState) => state.questions.questionNumber
  );
  const questionJourney = useSelector(
    (state: RootState) => state.questions.questionJourney
  );
  const noofJourneyQuestions = useSelector(
    (state: RootState) => state.questions.questions.length
  );

  useEffect(() => {
    if (account) {
      authRefetch();
      categoryRefetch();
      questionRefetch();
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      setLoading(true);
      if (userData && categories && questions) {
        const { current_category_id, current_question_id } =
          userData.data?.survey_data;
        const data = JSON.parse(localStorage.getItem("userDOBAndGender") || JSON.stringify({age:0,gender:"",userId:""}));
        localStorage.setItem(
          "userDOBAndGender",
          JSON.stringify({
            ...data,
            age: userData.data?.user_details?.age,
            gender: userData.data?.user_details?.gender,
          })
        );
        dispatch(
          setCategories(
            [...categories.data].sort(
              (a, b) => a.display_order - b.display_order
            )
          )
        );
        dispatch(setQuestionJourney(current_category_id));
        dispatch(setQuestions(questions?.data));
        dispatch(setQuestionNumber(current_question_id));
        setLoading(false);
      }
    }
  }, [
    account,
    userData,
    categories,
    isLoading,
    categoriesLoading,
    userLoading,
  ]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        updateProgress({
          question: questionNumber,
          journey: questionJourney,
          noofQuestions: noofJourneyQuestions,
        })
      );
      dispatch(updateAllProgress(questionJourney - 1));
    }
  }, [isLoading, questionJourney, questionNumber, noofJourneyQuestions]);

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Routes>
            <Route element={<Bridge />} path="/bridge" />
          </Routes>
          <AuthenticatedTemplate>
            <Routes>
              <Route
                path="/"
                element={
                  <OnBoardingPage
                    completed={userData?.data?.survey_data?.is_completed}
                    demographics={userData?.data?.is_demographic}
                  />
                }
              ></Route>
              <Route path="/dimensions" element={<SlideCarousel />}></Route>
              <Route path="/questionnaire" element={<Questionnaire />}></Route>
              <Route path="/overview" element={<Overview />}></Route>
              <Route path="/success" element={<SuccessScreen />} />
              <Route path="/score" element={<ScoreCard />} />
              <Route path="/demographics" element={<Demographics />} />
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/" element={<Login />}></Route>
            </Routes>
          </UnauthenticatedTemplate>
        </>
      )}
    </>
  );
};

export default AppRoutes;

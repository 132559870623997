import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  useMsal,
} from "@azure/msal-react";
import ExitImage from "../../utils/images/ExitImg.png";
import { CloseIcon, ModalCloseIcon } from "../styled";


interface ILogoutModel {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LogoutModal: React.FC<ILogoutModel> = ({
  open,
  setOpen
}): React.ReactElement => {
  const { accounts,instance } = useMsal();
  const handleLogout=async ()=>{
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId || ""),
      postLogoutRedirectUri:"/",
    };
    await instance.logoutRedirect(logoutRequest);
  
  }
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      PaperProps={{ style: { margin: "0px" } }}
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        },
      }}
    > <DialogActions
    sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
  >
    <CloseIcon>
      <ModalCloseIcon
        data-testid="modal-close"
        onClick={() => setOpen(false)}
      />
    </CloseIcon>
  </DialogActions>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
                    <Box>
            <img src={ExitImage} alt="Leaving Early"></img>
          </Box>
          <Typography variant="h4" color={"#494E9D"}>
          Leaving so early?
          </Typography>
          <Typography variant="h6" color={"#6F6F6F"} align="center">
          Don’t worry! Your progress is saved and you can log in again with your mobile number
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            sx={{ color: "#494E9D" }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{ backgroundColor: "#494E9D" }}
            onClick={handleLogout}
          >
            Logout
          </Button>
  
        </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../..";

interface QuestionsState {
  categories: Category[];
  questionNumber: number;
  questionJourney: number;
  questions: Questions[];
  journeys: number;
}

export interface Category {
  category_key: string;
  display_order: number;
  max_score: number;
  name: string;
  _id: string;
}

interface Questions {
  _id: string;
  category: string;
  question: string;
  field_type: string;
  selected_options: string[];
  response: InputResponse[];
  options: Options[];
}

interface InputResponse {
  label: string;
  value: any;
  unit?: any;
}

export interface Options {
  _id: string;
  applicable_gender: number;
  disable_other_options: boolean;
  is_calculated: boolean;
  validation: boolean;
  is_active: boolean;
  question_id: string;
  field_type_name: string;
  label: string;
  field_validation: string;
  min_value: number;
  max_value: number;
  unit_of_mesaurement: string;
  applicable_age_group: number;
}

const initialState: QuestionsState = {
  categories: [],
  questionNumber: 0,
  questionJourney: 0,
  questions: [],
  journeys: 0,
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    removeResponse: (state, action: PayloadAction<string>) => {
      if (state.questions[state.questionNumber].field_type === "checkbox") {
        state.questions[state.questionNumber].selected_options =
          state.questions[state.questionNumber].selected_options.filter(
            (item) => item !== action.payload
          );
      }
    },
    addResponse: (state, action: PayloadAction<string>) => {
      if (state.questions[state.questionNumber].field_type === "checkbox") {
        const deselectId = state.questions[
          state.questionNumber
        ].options?.filter((item) => item.disable_other_options)[0]._id;
        if (deselectId) {
          if (
            action.payload === deselectId ||
            state.questions[state.questionNumber].selected_options.indexOf(
              deselectId
            ) > -1
          ) {
            state.questions[state.questionNumber].selected_options = [
              action.payload,
            ];
          } else if (
            state.questions[state.questionNumber].selected_options.indexOf(
              deselectId
            ) === -1
          ) {
            state.questions[state.questionNumber].selected_options.push(
              action.payload
            );
          }
        } else {
          state.questions[state.questionNumber].selected_options.push(
            action.payload
          );
        }
      } else {
        state.questions[state.questionNumber].selected_options = [
          action.payload,
        ];
      }
    },
    addInputResponse: (state, action: PayloadAction<InputResponse>) => {
      state.questions[state.questionNumber].response = [action.payload];
      state.questions[state.questionNumber].selected_options.push(
        action.payload?.label
      );
    },
    changeQuestion: (state, action: PayloadAction<number>) => {
      state.questionNumber += action.payload;
    },
    changeJourney: (state, action: PayloadAction<number>) => {
      state.questionJourney = action.payload;
      state.questionNumber = 0;
    },
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
      state.journeys = action.payload.length;
    },
    setQuestions: (state, action: PayloadAction<Questions[]>) => {
      state.questions = action.payload;
    },
    setQuestionNumber: (state, action: PayloadAction<string>) => {
      state.questionNumber = state.questions.findIndex(
        (question) => question._id === action.payload
      );
    },
    setQuestionFromWorkflow: (state, action: PayloadAction<number>) => {
      state.questionNumber = action.payload;
    },
    setQuestionJourney: (state, action: PayloadAction<string>) => {
      state.questionJourney =
        state.categories[
          state.categories.findIndex(
            (category) => category._id === action.payload
          )
        ].display_order;
    },
    resetQuestions: () => initialState,
  },
});

export const {
  removeResponse,
  addResponse,
  addInputResponse,
  setQuestionJourney,
  setQuestionNumber,
  changeQuestion,
  changeJourney,
  setCategories,
  resetQuestions,
  setQuestions,
  setQuestionFromWorkflow,
} = questionsSlice.actions;

export const selectQuestion = (state: RootState) =>
  state.questions.questions[state.questions.questionNumber];

export const isLastQuestion = (state: RootState) =>
  state.questions.questionNumber === state.questions.questions.length - 1;

export const isLastJourney = (state: RootState) =>
  state.questions.questionJourney === state.questions.journeys;

export const getCurrentCategoryId = (state: RootState) =>
  state.questions.categories.find(
    (category) => category.display_order === state.questions.questionJourney
  );

export default questionsSlice.reducer;

import {
    ALTIcon,
    B12Icon,
    BMIIcon,
    BPIcon,
    EGFRIcon,
    VITDIcon,
    LDLIcon,
    HBA1CIcon,
    HBIcon,
    CreatinineIcon,
    TSHIcon
} from "../../utils"
export type StatsData = {
    label: string;
    description: string;
    icon: React.ReactNode;
    avatarColor: string;
}

export const StatsContent: StatsData[] = [
    {
        icon: <LDLIcon />,
        description: "<strong>Lipid Profile Test</strong>, includes the LDL test (Low-Density Lipoprotein) which measures \"bad\" cholesterol in the blood, aiding in cardiovascular risk assessment.",
        label: "LDL",
        avatarColor: "#FFF1F0",
    },
    {
        icon: <HBA1CIcon />,
        description: "<strong>Glycosylated Hemoglobin test measures the average blood sugar levels</strong> over the past 3 months. It helps diagnose diabetes & monitor its treatment.",
        label: "HbA1c",
        avatarColor: "#fcf7e8",
    },
    {
        icon: <TSHIcon />,
        description: "<strong>Thyroid Profile Test</strong> includes the TSH (Thyroid Stimulating Hormone) test, which assesses thyroid function and detects thyroid disorders",
        label: "TSH",
        avatarColor: "#e8f4ff",
    },
    {
        icon: <HBIcon />,
        description: "<strong>Complete Blood Count</strong> tests include the Hb% (Hemoglobin Percentage) test, which measures blood hemoglobin levels, a vital anemia indicator.",
        label: "HB% CBC",
        avatarColor: "#f5f1e9",
    },
    {
        icon: <BPIcon />,
        description: "<strong>This test assesses the force of blood against arterial walls.</strong> It helps diagnose hypertension & monitor cardiovascular health.",
        label: "Blood Pressure",
        avatarColor: "#f7eeed",
    },
    {
        icon: <B12Icon />,
        description: "<strong>The Vitamin B12 test measures blood levels of B12.</strong> It's done to diagnose deficiencies, anemia, and neurological issues.",
        label: "Vitamin B12",
        avatarColor: "#f4f0fa",
    },
    {
        icon: <VITDIcon />,
        description: "<strong>A Vitamin D (25-Hydroxy) test measures blood levels of Vitamin D</strong>, crucial for bone health, immune function, and overall well-being.",
        label: "Vitamin D",
        avatarColor: "#edf3f7",
    },
    {
        icon: <ALTIcon />,
        description: "<strong>Liver Function Tests include the Alanine Transaminase test</strong>, which evaluates liver function and detects liver damage or disease.",
        label: "ALT",
        avatarColor: "#ebf7ec",
    },
    {
        icon: <CreatinineIcon />,
        description: "<strong>Kidney Function Tests include this test</strong>, a key indicator of kidney function, which helps diagnose and monitor kidney diseases.",
        label: "Creatinine",
        avatarColor: "#faedf2",
    },
    {
        icon: <EGFRIcon />,
        description: "<strong>Estimated Glomerular Filtration Rate (eGFR) test is used to evaluate kidney function</strong> or diagnose and monitor any kidney disease and its treatment.",
        label: "eGFR",
        avatarColor: "#edeff2ff",
    },

    {
        icon: <BMIIcon />,
        description: "<strong>BMI (Body Mass Index) is a measurement based on height and weight.</strong> It assesses body fat and helps gauge potential health risks.",
        label: "BMI",
        avatarColor: "#f6edfa",
    },
]
import { useEffect, memo } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

const Bridge = memo(() => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const gender = searchParams.get('gender')
    const userId = searchParams.get('userid')
    const age = searchParams.get('age')

    localStorage.setItem(
      'userDOBAndGender',
      JSON.stringify({
        gender,
        userId,
        age,
      })
    )
    navigate('/')
  }, [navigate, searchParams])

  return null
})

export default Bridge

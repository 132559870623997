import React from "react";
import { Box } from "@mui/material";
import LoaderGif from "../../utils/images/Loader.gif";

const Loader: React.FC = (): React.ReactElement => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img alt="animation" width={450} src={LoaderGif} />
    </Box>
  );
};

export default Loader;

import React, { useEffect, useState } from "react";
import {
    Box,
    Dialog,
    Slide,
    Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DrumRollModel: React.FC<any> = ({
    open,
    setOpen,
    inputData,
    min,
    max,
    handleInputValue,
    unit
}): React.ReactElement => {
    const feetInches = inputData?.toString().split('.');
    const feetValue = feetInches[0] ? parseInt(feetInches[0]) : 1;
    const inchesValue = feetInches[1] ? parseInt(feetInches[1]) : 0;
    const centimetersArray = [];
    const feetsArray = [];
    const inchesArray = [];
    const kgsArray = [];
    const [selectedValue, setSelectedValue] = useState<any>();
    const [valueInFeet, setValueInFeet] = useState<any>(feetValue);
    const [valueInInches, setValueInInches] = useState<any>(inchesValue);
    for (let i = min; i <= max; i++) {
        centimetersArray.push({
            label: `${i} ${unit}`,
            value: i,
        });
    }
    for (let i = 1; i <= 12; i++) {
        feetsArray.push({
            label: `${i} ft`,
            value: i,
        });
    }
    for (let i = 0; i <= 11; i++) {
        inchesArray.push({
            label: `${i} in`,
            value: i,
        });
    }
    for (let i = min; i <= max; i++) {
        if (i === 0 || i === 1) {
            kgsArray.push({
                label: `${i} Kg`,
                value: i,
            });
        } else {
            kgsArray.push({
                label: `${i} ${unit}`,
                value: i,
            });
        }
    }
    useEffect(() => {
        setSelectedValue(inputData ? inputData : min);
        if (unit === "ft in")
            setSelectedValue(`${valueInFeet}.${valueInInches}`);
    }, [valueInFeet, valueInInches, unit]);
    const handleClick = (num: any) => {
        setSelectedValue(num);
    };
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { }}
            PaperProps={{ style: { margin: "0px" } }}
            sx={{
                "& .MuiDialog-container": {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                },
            }}
        >

            <Box
                onClick={() => {
                    handleInputValue(Number(selectedValue), min, max);
                    setOpen(false);
                    setSelectedValue("")
                }}
                sx={{
                    color: "#494E9D",
                    textAlign: "end",
                    fontFamily: "Nunito Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    margin: "9px 25px 9px 16px",
                    cursor: "pointer",
                    border: "none"
                }}
            >
                Done
            </Box>
            <Box
                sx={{
                    height: 300,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                    borderRadius: "4px",
                    overflowY: "auto",
                    p: 1,
                }}
            >
                {unit?.toLowerCase() === "cm" &&
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        {centimetersArray.map((num: any, index: number) => (
                            <Typography
                                key={index}
                                variant="body2"
                                onClick={() => handleClick(num.value)}
                                sx={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    backgroundColor: num.value === selectedValue ? "#F6F6F8" : "white",
                                    color: num.value === selectedValue ? "#494E9D" : "#5A5A5A",
                                    opacity: num.value === selectedValue ? 1 : 0.5,
                                    "&:hover": {
                                        backgroundColor: "#F6F6F8",
                                    },
                                    width: "100%",
                                    textAlign: "center",
                                    fontFamily: "Nunito Sans",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                }}

                            >
                                {num.label}
                            </Typography>
                        ))}
                    </Box>
                }
                {unit?.toLowerCase() === "ft in" &&
                    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                        }}>
                            {feetsArray.map((num: any, index: number) => (
                                <Typography
                                    key={index}
                                    variant="body2"
                                    onClick={() => {
                                        setValueInFeet(num.value);
                                    }}
                                    sx={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        backgroundColor: num.value === valueInFeet ? "#F6F6F8" : "white",
                                        color: num.value === valueInFeet ? "#494E9D" : "#5A5A5A",
                                        opacity: num.value === valueInFeet ? 1 : 0.5,
                                        "&:hover": {
                                            backgroundColor: "#F6F6F8",
                                        },
                                        width: "100%",
                                        textAlign: "center",
                                        fontFamily: "Nunito Sans",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                    }}
                                >
                                    {num.label}
                                </Typography>
                            ))}
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                        }}>
                            {inchesArray.map((num: any, index: number) => (
                                <Typography
                                    key={index}
                                    variant="body2"
                                    onClick={() => {
                                        setValueInInches(num.value);
                                    }}
                                    sx={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        backgroundColor: num.value === valueInInches ? "#F6F6F8" : "white",
                                        color: num.value === valueInInches ? "#494E9D" : "#5A5A5A",
                                        opacity: num.value === valueInInches ? 1 : 0.5,
                                        "&:hover": {
                                            backgroundColor: "#F6F6F8",
                                        },
                                        width: "100%",
                                        textAlign: "center",
                                        fontFamily: "Nunito Sans",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                    }}
                                >
                                    {num.label}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                }
                {unit?.toLowerCase() === "kgs" &&
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        {kgsArray.map((num: any, index: number) => (
                            <Typography
                                key={index}
                                variant="body2"
                                onClick={() => handleClick(num.value)}
                                sx={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    backgroundColor: num.value === selectedValue ? "#F6F6F8" : "white",
                                    color: num.value === selectedValue ? "#494E9D" : "#5A5A5A",
                                    opacity: num.value === selectedValue ? 1 : 0.5,
                                    "&:hover": {
                                        backgroundColor: "#F6F6F8",
                                    },
                                    width: "100%",
                                    textAlign: "center",
                                    fontFamily: "Nunito Sans",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                }}
                            >
                                {num.label}
                            </Typography>
                        ))}
                    </Box>
                }
            </Box>
        </Dialog >
    );
};

export default DrumRollModel;

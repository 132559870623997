import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HomeContainer } from "../../components/styled";
import Scrollbar from "../../components/Scrollbar";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExitModel from "../../components/ExitModel";
import { CrossIcon, DemographicIcon } from "../../utils";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useGetQuestionsQuery, usePostDemographicsMutation } from "../../store/features/api";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCategoryId, setQuestions } from "../../store/features/questions";

const validationSchema = Yup.object().shape({
  age: Yup.number()
    .typeError("Age must be a number")
    .positive("Age must be positive")
    .integer("Age must be an integer")
    .required("Age is required"),
  gender: Yup.string().required("Gender is required"),
})

interface IDemographics { }

const Demographics: React.FC<IDemographics> = (): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [exit, setExit] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [postDemographics] = usePostDemographicsMutation();

  const [formValues, setFormValues] = React.useState<{ age: number, gender: string }>({
    age: 0,
    gender: ''
  })

  const category = useSelector((state: any) =>
    getCurrentCategoryId(state)
  );
  const {
    data: questions,
    isLoading,
    refetch: questionRefetch,
  } = useGetQuestionsQuery({ categoryId: category?._id });
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDOBAndGender") || JSON.stringify({ age: 0, gender: "", userId: "" }));
    if (userDetails?.age && userDetails?.age != "" && userDetails?.age > 0) {
      setFormValues((values) => ({
        ...values,
        age: userDetails?.age
      }))
    }
    if (userDetails?.gender) {
      setFormValues((values) => ({
        ...values,
        gender: userDetails?.gender
      }))
    }
  }, []);

  console.log(formValues);
  const handleSubmit = (values: any) => {
    setLoading(true);
    const data = JSON.parse(localStorage.getItem("userDOBAndGender") || JSON.stringify({ age: 0, gender: "", userId: "" }));
    localStorage.setItem(
      "userDOBAndGender",
      JSON.stringify({ ...data, age: values.age, gender: values.gender })
    );
    postDemographics({ ...values, svaas_user_id: data.userId })
      .then((data: any) => {
        if (data.data.status_code === 200) {
          setLoading(false);
          questionRefetch().then(() => {
            dispatch(setQuestions(questions?.data));
            navigate("/dimensions");
          });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <HomeContainer>
      <Scrollbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 0,
            background: `#797ec9`,
            padding: 3,
            height: "15vh",
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
          }}
        >
          <Box>
            <Typography variant="h4" fontSize={20} color={"#FFFFFF"}>
              Health 360
            </Typography>
            <Button
              onClick={() => {
                setOpen(true);
                setExit(false);
              }}
              variant="text"
              sx={{
                color: "#FFFFFF",
                fontStyle: "italic",
                padding: 0,
                textDecoration: "underline",
              }}
            >
              What is this?
            </Button>
          </Box>
          <IconButton
            onClick={() => {
              setOpen(true);
              setExit(true);
            }}
          >
            <CrossIcon width={15} height={15} />
          </IconButton>
        </Box>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "75vh",
            border: "1px solid #E5E5E5",
            margin: 2,
            marginTop: 8,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              marginTop: -4,
              display: "flex",
              justifyContent: "center",
              width: "88%",
            }}
          >
            <Box sx={{ borderRadius: "50%" }}>
              <Box
                sx={{
                  background: `#797ec9`,
                  borderRadius: "50%",
                  p: 0.3,
                }}
              >
                <Avatar
                  variant="circular"
                  sx={{
                    backgroundColor: `#797ec9`,
                    width: 60,
                    height: 60,
                    border: "3px solid white",
                  }}
                >
                  {<DemographicIcon width={30} height={30} />}
                </Avatar>
              </Box>
            </Box>
          </Box>
          <CardHeader
            title="Please provide your demographic information"
            sx={{
              paddingTop: 8,
              textAlign: "center",
              paddingBottom: 0,
              " &.MuiCardHeader-root .MuiCardHeader-title": {
                fontSize: 18,
              },
            }}
          />
          <CardContent>
            <Formik
              initialValues={formValues}
              enableReinitialize
              validationSchema={validationSchema}
              validateOnMount={true}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, isValid, dirty }) => {
                return (
                  <Form>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        height: "500px",
                        my: 2,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", gap: 3 }}
                      >
                        <Field
                          as={TextField}
                          label={
                            <Typography fontSize={18} fontWeight={400}>
                              Age
                            </Typography>
                          }
                          name="age"
                          fullWidth
                          helperText={
                            <div style={{ color: "red" }}>
                              <ErrorMessage name="age" />
                            </div>
                          }
                        />
                        <FormControl fullWidth>
                          <InputLabel sx={{ fontSize: 18, fontWeight: 400 }}>
                            Gender
                          </InputLabel>
                          <Field
                            as={Select}
                            label={<Typography fontSize={18}>Gender</Typography>}
                            name="gender"
                            helperText={
                              <div style={{ color: "red" }}>
                                <ErrorMessage name="gender" />
                              </div>
                            }
                          >
                            {["Male", "Female"].map(
                              (gender: string, i: number) => (
                                <MenuItem key={i} value={gender}>
                                  {gender}
                                </MenuItem>
                              )
                            )}
                          </Field>
                        </FormControl>
                      </Box>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        fullWidth
                        loading={loading}
                        sx={{ backgroundColor: "#797ec9", fontSize: "16px" }}
                        size="large"
                        disabled={!isValid}
                      >
                        Next Question
                      </LoadingButton>
                    </Box>
                  </Form>
                )
              }}
            </Formik>
          </CardContent>
        </Card>
        <ExitModel open={open} setOpen={setOpen} exit={exit} />
      </Scrollbar>
    </HomeContainer>
  );
};

export default Demographics;

import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StepData } from "../../services/CategoriesData";

interface ISlide {
  category: StepData;
}

const Slide: React.FC<ISlide> = ({ category }): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", p: 6, height: "50vh" }}>
          <img src={category.overViewImage} />
        </Box>
        <Box
          p={2}
          sx={{
            backgroundColor: category.categorySecondaryColor,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 2
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                position: "absolute",
                marginTop: -5,
                backgroundColor: category.categoryPrimaryColor,
                p: 1,
                borderRadius: 1,
              }}
            >
              <Typography
                textAlign={"center"}
                variant="h3"
                sx={{ color: "white" }}
              >
                {category.label.toUpperCase()}
              </Typography>
            </Box>
          </Box>
          <Box py={1}>
            <Typography variant="h4">What this dimension does?</Typography>
            <Typography variant="body1">{category.dimensionDoes}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">How it will help you?</Typography>
            {category.keyPoints.map((keyPoint, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems:"center",
                  gap: 1,
                  py: 1,
                }}
              >
                <Avatar
                  variant="circular"
                  sx={{
                    backgroundColor: `${category?.categoryPrimaryColor}`,
                    width: 40,
                    height: 40,
                  }}
                >
                  {keyPoint.keyIcon}
                </Avatar>
                <Typography>
                  <span style={{fontWeight: 700}}>{keyPoint.KeyHeading} </span>
                  <span style={{fontWeight: 400}}>{keyPoint.keyDescription}</span>
                </Typography>
              </Box>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/questionnaire")}
            fullWidth
          >
            {category.ctaText}
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Slide;

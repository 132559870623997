import { configureStore } from "@reduxjs/toolkit";
import questionReducer from "./features/questions";
import progressReducer from "./features/progress";
import { api } from "./features/api";

const store = configureStore({
    reducer: {
        questions: questionReducer,
        progress: progressReducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  });

  export type RootState = ReturnType<typeof store.getState>

  export type AppDispatch = typeof store.dispatch
  
  export default store;
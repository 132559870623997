import React, { memo } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config";
import { useNavigate } from "react-router-dom";

interface ILogin {}

const Login: React.FC<ILogin> = memo(() => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    } else {
      instance.loginRedirect(loginRequest);
    }
  }, [isAuthenticated, navigate]);

  return null;
});

export default Login;

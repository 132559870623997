import React from "react";
import "./App.css";
import ThemeProviderWrapper from "./theme";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
      <ThemeProviderWrapper>
        <BrowserRouter>
          <div className="app">
            <AppRoutes />
          </div>
          <ToastContainer />
        </BrowserRouter>
      </ThemeProviderWrapper>
    </Provider>
  );
}

export default App;

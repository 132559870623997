import axios from "axios";
import { loginRequest } from "../../config/msalConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "../../";

export const refreshAccessToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  try {
    const token = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
    return token.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenRedirect(loginRequest)
        .then((response: any) => {
          return response.accessToken;
        })
        .catch((err) => console.log(err));
    } else {
      console.log(error);
    }
  }
};

const Http = axios.create();

Http.interceptors.request.use(
  async (config: any) => {
    const token = await refreshAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    } else {
      return Promise.reject(new Error("No token available"));
    }
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

export default Http;

import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { StatsContent } from "./statsContent";
import Scrollbar from "../Scrollbar";

interface IStatsModel {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const ShowArticle = (htmlContent: any) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
const StatsModel: React.FC<IStatsModel> = ({
  open,
  setOpen,
}): React.ReactElement => {
  const statsData = StatsContent;
  return (
    <Dialog
      open={open}
      fullScreen
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      PaperProps={{ style: { margin: "0px" } }}
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FCBF6C",
          color: "white",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          height: "10%",
        }}
      >
        <IconButton
          size="large"
          sx={{ color: "white" }}
          onClick={() => setOpen(false)}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Typography variant="h4">Health Parameters</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{
          color: "#5A5A5A",
          fontFamily: "Nunito Sans",
          fontSize: "14px",
          fontWeight: 600,
          textAlign: "center"
        }} py={2}>
          What are these values & where I can find these?
        </Typography>
        <Box height={"89%"}>
          <Scrollbar>
            <Grid container spacing={2} py={2}>
              {statsData.map((stats, index) => (
                <Grid item xs={6} key={index}>
                  <Card>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        justifyContent: "flex-start",
                      }}
                    >
                      <Avatar sx={{ backgroundColor: `${stats.avatarColor}` }}>
                        {stats.icon}
                      </Avatar>
                      <Typography variant="h5" px={1}>
                        {stats.label}
                      </Typography>
                      <Typography variant="body1" px={1}>
                        {ShowArticle(stats.description)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Scrollbar>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StatsModel;

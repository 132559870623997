import { Configuration, LogLevel } from '@azure/msal-browser';

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_AZURE_USER_FLOW,
    forgotPassword: process.env.REACT_APP_AZURE_FORGOT_PASSWORD,
    editProfile: process.env.REACT_APP_AZURE_Edit_PROFILE,
  },
  authorities: {
    signUpSignIn: {
      authority: `${process.env.REACT_APP_AZURE_AUTHORITY_URL}/${process.env.REACT_APP_AZURE_USER_FLOW}`,
    },
    forgotPassword: {
      authority: `${process.env.REACT_APP_AZURE_AUTHORITY_URL}/${process.env.REACT_APP_AZURE_FORGOT_PASSWORD}`,
    },
    editProfile: {
      authority: `${process.env.REACT_APP_AZURE_AUTHORITY_URL}/${process.env.REACT_APP_AZURE_Edit_PROFILE}`,
    },
  },
  authorityDomain: process.env.REACT_APP_AZURE_AUTHORITY_DOMAIN_URL as string,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENTID as string, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URL as string, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
}

export const protectedResources = {
  apiHello: {
    endpoint: "http://localhost:5000/hello",
    scopes: [
      "openid",
      "profile",
      `openid ${process.env.REACT_APP_AZURE_SCOPE}`,
    ],
  },
};

export const loginRequest = {
  scopes: [...protectedResources.apiHello.scopes],
};

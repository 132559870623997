import React from "react";
import { Slide } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  getCurrentCategoryId,
  setQuestions,
} from "../../store/features/questions";
import { useGetQuestionsQuery } from "../../store/features/api";
import { Box, Skeleton } from "@mui/material";
import { StepData, steps } from "../../services/CategoriesData";

const SlideCarousel: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const category = useSelector((state: RootState) =>
  getCurrentCategoryId(state)
  );
  const {data: questions, isLoading} = useGetQuestionsQuery({categoryId:category?._id});
  const slideData = steps.find(
    (step) => step.categoryId === category?.category_key
  );

  React.useEffect(() => {
    if(!isLoading) {
      dispatch(setQuestions(questions?.data));
    }
  }, [questions, isLoading]);

  if(isLoading) {
    return <Box sx={{display: "flex", flexDirection: "column", height: "100vh", px: 3, py: 7, gap: 1}}>
      <Skeleton animation="wave" variant="rounded" height={800}></Skeleton>
      <Skeleton variant="rounded" height={100}></Skeleton>
    </Box>
  }

  return (
    <Slide
      category={slideData ?? {} as StepData}
    />
  );
};

export default SlideCarousel;

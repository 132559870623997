import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { AxiosError } from "axios";
import { Http } from "../../../services";

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: string;
      data?: any;
      params?: any;
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await Http({ url: baseUrl + url, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const api = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}` }),
  endpoints: (builder) => ({
    getQuestions: builder.query({
      query: ({ categoryId }) => ({
        url: `questions/${categoryId}`,
        method: "get",
      }),
    }),
    getAuthenticate: builder.query({
      query: () => {
        return {
          url: `authenticate`,
          method: "get",
        };
      },
    }),
    getCategories: builder.query({
      query: () => ({
        url: `categories`,
        method: `get`,
      }),
    }),
    postAnswers: builder.mutation({
      query: (data) => ({
        url: `answers`,
        method: `post`,
        data: data,
      }),
    }),
    getTotalScore: builder.query({
      query: () => ({
        url: `totalscore`,
        method: `get`,
      }),
    }),
    postDemographics: builder.mutation({
      query: (data) => ({
        url: `authenticate`,
        method: `post`,
        data: data,
      }),
    }),
    postRetakeSurvey: builder.mutation({
      query: (data) => ({
        url: `survey`,
        method: `post`,
        data: data,
      }),
    }),
    postFinalReport: builder.mutation({
      query: (data) => ({
        url: `finalreport`,
        method: `post`,
        data: data,
      }),
    }),
  }),

});

export const {
  useGetQuestionsQuery,
  useGetAuthenticateQuery,
  useGetCategoriesQuery,
  usePostAnswersMutation,
  useGetTotalScoreQuery,
  usePostDemographicsMutation,
  usePostRetakeSurveyMutation,
  usePostFinalReportMutation
} = api;

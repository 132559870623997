import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Scrollbar from "../../components/Scrollbar";
import { steps } from "../../services/CategoriesData";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Overview: React.FC = (): React.ReactElement => {
  const [card, setCard] = React.useState<number>(-1);
  const navigate = useNavigate();
  const progress = useSelector((state: RootState) => state.progress.progress);
  const overviewInfo = steps.slice(1, steps.length);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          backgroundColor: "#494E9D",
        }}
      >
        <Scrollbar>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: 1, backgroundColor: "white", borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}
            padding={2}
          >
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              paddingTop={2}
            >
              <IconButton onClick={() => navigate(-1)}>
                <KeyboardBackspaceIcon
                  fontSize="medium"
                  sx={{ color: "#494E9D" }}
                />
              </IconButton>
              <Typography variant="h4" color={"#494E9D"}>
                What is Health 360?
              </Typography>
            </Box>
            <Typography variant="h6" color={"#a7a7a7"}>
              Health360 is a dynamic health index designed to continuously
              monitor and assess an individual's health behaviors and status.
              Unlike a static snapshot, this index provides real-time insights
              into health, reflecting the proactive steps and lifestyle changes
              the individuals make. By evaluating five key dimensions -
              Awareness, Healthy Lifestyle, Health Stats, Emotional Wellbeing,
              and Health Screening
            </Typography>
          </Box>
          <Box padding={2}>
            <Typography variant="h4" color={"#FFFFFF"} py={1}>
              All Dimensions
            </Typography>
            <Grid container spacing={2} py={2}>
              {overviewInfo.map((item, index) => (
                <Grid item key={index} xs={12}>
                  <Card sx={{ backgroundColor: item.categorySecondaryColor }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Box
                          padding={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <Avatar
                            variant="rounded"
                            sx={{ backgroundColor: item.categoryPrimaryColor }}
                          >
                            {item.categoryIcon}
                          </Avatar>
                          <Typography variant="h4" fontSize={15}>{item.label}</Typography>
                          {card !== index ? (
                            <Button
                              variant="contained"
                              size="small"
                              sx={{borderRadius: 3}}
                              onClick={() => setCard(index)}
                            >
                              Know more
                            </Button>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          m={1}
                          height={160}
                          sx={{
                            backgroundImage: `url(${item.overViewImage})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                            backgroundPosition: "center",
                          }}
                        ></Box>
                      </Grid>
                      {card === index ? (
                        <CardContent sx={{ backgroundColor: "white" }}>
                          <Typography>{item.longDescription}</Typography>
                        </CardContent>
                      ) : null}
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{backgroundColor: "white"}} py={2} px={3}>
          <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/dimensions")}
              fullWidth
            >
              {progress[0] > 1 ? "Resume" : "Begin"} Assessment
            </Button>
          </Box>
        </Scrollbar>
      </Box>
    </React.Fragment>
  );
};

export default Overview;

import React from "react";
import { HomeContainer } from "../../components/styled";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "../../utils";
import { ProgressContainer, QuestionsContainer } from "../../components";
import ExitModel from "../../components/ExitModel";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { steps } from "../../services/CategoriesData";
import { getCurrentCategoryId } from "../../store/features/questions";
import Scrollbar from "../../components/Scrollbar";

interface IQuestionnaire {}

const Questionnaire: React.FC<IQuestionnaire> = (): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [exit, setExit] = React.useState<boolean>(false);
  const currentCategory = useSelector((state: RootState) =>
    getCurrentCategoryId(state)
  );
  const categoryProps = steps.find(
    (step) => step.categoryId === currentCategory?.category_key
  );
  return (
    <HomeContainer>
      <Scrollbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 0,
            background: `${categoryProps?.categoryPrimaryColor}`,
            padding: 3,
            height: 150,
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
          }}
        >
          <Box>
            <Typography variant="h6" color={"#FFFFFF"}>
              Health 360
            </Typography>
            <Button
              onClick={() => {
                setOpen(true);
                setExit(false);
              }}
              variant="text"
              sx={{
                color: "#FFFFFF",
                fontStyle: "italic",
                padding: 0,
                textDecoration: "underline",
              }}
            >
              What is this?
            </Button>
          </Box>
          <IconButton
            onClick={() => {
              setOpen(true);
              setExit(true);
            }}
          >
            <CrossIcon width={15} height={15} />
          </IconButton>
        </Box>
        <Box sx={{ padding: 3, marginTop: -8, flexGrow: 0 }}>
          <ProgressContainer />
        </Box>
        <Box sx={{ padding: 3, flexGrow: 1 }}>
          <QuestionsContainer />
        </Box>
        <ExitModel open={open} setOpen={setOpen} exit={exit} />
      </Scrollbar>
    </HomeContainer>
  );
};

export default Questionnaire;

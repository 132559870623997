import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import Scrollbar from "../Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  addResponse,
  addInputResponse,
  removeResponse,
  changeQuestion,
  selectQuestion,
  isLastQuestion,
  isLastJourney,
  getCurrentCategoryId,
} from "../../store/features/questions";
import { AppDispatch, RootState } from "../../store/index";
import { updateProgress } from "../../store/features/progress";
import { usePostAnswersMutation } from "../../store/features/api";
import { steps } from "../../services/CategoriesData";
import DimensionModel from "../DimensionModel";
import MultiInputContainer from "../MultiInputContainer";
import StatsModel from "../StatsModel";
import LoadingButton from "@mui/lab/LoadingButton";
import DrumRollModel from "../DrumRollModel";
import { QuestionMark } from "../../utils";

interface QuestionsContainerProps { }
const QuestionsContainer: React.FC<
  QuestionsContainerProps
> = (): React.ReactElement => {
  const [dimensionModel, setDimensionModel] = React.useState<boolean>(false);
  const [statsModel, setStatsModel] = React.useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const userDetails = JSON.parse(
    localStorage.getItem("userDOBAndGender") || ""
  );
  const questionNumber = useSelector(
    (state: RootState) => state.questions.questionNumber
  );
  const questionJourney = useSelector(
    (state: RootState) => state.questions.questionJourney
  );
  const noofJourneyQuestions = useSelector(
    (state: RootState) => state.questions.questions.length
  );
  const question = useSelector((state: RootState) => selectQuestion(state));
  const lastJourney = useSelector((state: RootState) => isLastJourney(state));
  const isLast = useSelector((state: RootState) => isLastQuestion(state));
  const [inputValue, setInputValue] = useState<any>("");
  const [inputData, setInputData] = useState<any>("");
  const [measurementUnit, setMeasurementUnit] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [lastData, setLastData] = useState<any>({});
  const [openDrumRoll, setOpenDrumRoll] = useState<boolean>(false);
  const [postAnswers] = usePostAnswersMutation();
  const selectedOptions = useSelector(
    (state: RootState) =>
      state.questions?.questions[questionNumber]?.selected_options
  );
  const currentCategory = useSelector((state: RootState) =>
    getCurrentCategoryId(state)
  );
  const categoryProps = steps.find(
    (step) => step.categoryId === currentCategory?.category_key
  );

  useEffect(() => {
    setInputValue(question?.response[0]?.value || "");
    setInputData(question?.response[0]?.value || "");
    setMeasurementUnit(question?.response[0]?.unit || question?.options[0]?.unit_of_mesaurement?.split(",")[0]);
  }, [question, questionNumber]);

  const handleNext = () => {
    setLoading(true);
    const data = {
      question_id: question._id,
      options: selectedOptions,
      response: [],
    };
    if (isLast) {
      if (question.field_type === "input") {
        dispatch(
          addInputResponse({
            label: question.options[0]._id,
            value: inputValue,
            unit: measurementUnit,
          })
        );
        setInputValue("");
        setMeasurementUnit("");
        const result = {
          question_id: question._id,
          options: [question.options[0]._id],
          response: [
            {
              label: question.options[0]._id,
              value: inputValue,
              unit: measurementUnit,
            },
          ],
        };
        if (lastJourney) {
          setLastData(result);
          setDimensionModel(true);
        } else {
          postAnswers(result)
            .then((data: any) => {
              if (data?.data?.status_code === 200) {
                setLoading(false);
                setDimensionModel(true);
              }
            })
            .catch((err) => console.log(err));
        }
      } else {
        if (lastJourney) {
          setLastData(data);
          setDimensionModel(true);
        } else {
          postAnswers(data)
            .then((data: any) => {
              if (data?.data?.status_code === 200) {
                setLoading(false);
                setDimensionModel(true);
              }
            })
            .catch((err) => console.log(err));
        }
      }
    } else {
      if (question.field_type === "input") {
        dispatch(
          addInputResponse({
            label: question.options[0]._id,
            value: inputValue,
            unit: measurementUnit,
          })
        );
        setInputValue("");
        setMeasurementUnit("");
        const result = {
          question_id: question._id,
          options: [question.options[0]._id],
          response: [
            {
              label: question.options[0]._id,
              value: inputValue,
              unit: measurementUnit,
            },
          ],
        };
        postAnswers(result)
          .then((data: any) => {
            if (data?.data?.status_code === 200) {
              dispatch(
                updateProgress({
                  question: questionNumber,
                  journey: questionJourney,
                  noofQuestions: noofJourneyQuestions,
                })
              );
              dispatch(changeQuestion(1));
              setLoading(false);
            }
          })
          .catch((err) => console.log(err));
      } else {
        postAnswers(data)
          .then((data: any) => {
            if (data?.data?.status_code === 200) {
              dispatch(
                updateProgress({
                  question: questionNumber,
                  journey: questionJourney,
                  noofQuestions: noofJourneyQuestions,
                })
              );
              dispatch(changeQuestion(1));
              setLoading(false);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleStats = (values: any) => {
    setLoading(true);
    const options = Object.keys(values).map((label) => {
      const id =
        question.options.find((option) => option.label === label)?._id || null;
      return id;
    });
    const response = Object.keys(values).map((label) => {
      const id =
        question.options.find((option) => option.label === label)?._id || null;
      return { label: id, value: values[label] || "" };
    });
    const data = {
      question_id: question._id,
      options,
      response,
    };
    setLastData(data);
    setDimensionModel(true);
    // postAnswers(data)
    //   .then((data: any) => {
    //     if (data?.data?.status_code === 200) {
    //       if (isLast) {
    //         setDimensionModel(true);
    //         setLoading(false);
    //       } else {
    //         dispatch(changeQuestion(1));
    //         setLoading(false);
    //       }
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  const getItemGender = (gender: number) => {
    switch (gender) {
      case 1:
        return "all";
      case 2:
        return "male";
      case 3:
        return "female";
      default:
        break;
    }
  };

  const handleInputValue = (value: number, min: number, max: number) => {
    setInputData(value ? value : "");
    setInputValue(value);
  };

  const cardContent = () => {
    if (question?.field_type === "input" && question.options?.length > 1) {
      return (
        <CardContent sx={{ flexGrow: 1, px: 2, py: 1 }}>
          {/* <Scrollbar> */}
          <MultiInputContainer
            options={question?.options}
            handleStats={handleStats}
            loader={loading}
            primaryColor={categoryProps?.categoryPrimaryColor ?? ""}
            secondaryColor={categoryProps?.categorySecondaryColor ?? ""}
          />
          {/* </Scrollbar> */}
        </CardContent>
      );
    } else if (question?.field_type === "input") {
      return (
        <CardContent sx={{ flexGrow: 1 }}>
          <Box sx={{
            display: "flex",
          }}>
            <OutlinedInput
              sx={{ width: "100rem", borderRadius: "4px 0px 0px 4px" }}
              size="small"
              fullWidth
              value={inputData}
              placeholder={measurementUnit?.toLowerCase() === "ft in" ? "Eg. 5.3" : "Eg. 163"}
              inputProps={{
                min: `${question.options[0].min_value ?? 0}`,
                max: `${question.options[0].max_value ?? 0}`,
                step:
                  question.options[0].field_validation === "Decimal" ? 0.1 : 1,
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (question._id !== "6569665fc3674705b3a70bde" && question._id !== "65696796c3674705b3a70be8") { //dont want for two specific questions
                  handleInputValue(
                    +event.target.value,
                    question.options[0].min_value,
                    question.options[0].max_value
                  )
                }
              }
              }
              onClick={(e) => {
                if (question._id === "6569665fc3674705b3a70bde" || question._id === "65696796c3674705b3a70be8") { // want for two specific questions
                  setOpenDrumRoll(true);
                }
              }}
            ></OutlinedInput>
            {question?.options[0]?.unit_of_mesaurement.split(",").length > 1 && measurementUnit &&
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select
                  sx={{ borderRadius: "0px 4px 4px 0px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={<Typography fontSize={18}>Unit</Typography>}
                  value={measurementUnit}
                  onChange={(e: any) => {
                    setMeasurementUnit(e.target.value);
                    setInputData("");
                    setInputValue("");
                  }}
                >
                  {question?.options[0]?.unit_of_mesaurement?.split(",").map(
                    (unit: string, i: number) => (
                      <MenuItem key={i} value={unit}>
                        {unit}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            }
          </Box>
          {/* {validInput ? (
            <Typography variant="body2" sx={{ color: "red" }} py={1}>
              Value should be between {question.options[0].min_value} -{" "}
              {question.options[0].max_value}
            </Typography>
          ) : null} */}
        </CardContent>
      );
    } else {
      return (
        <CardContent sx={{ flexGrow: 1 }}>
          {/* <Scrollbar> */}
          <List>
            {question?.options &&
              question?.options.map((item) => {
                return question?.selected_options.includes(item._id) ? (
                  <ListItem
                    key={item._id}
                    sx={{
                      border: `1px solid ${categoryProps?.categoryPrimaryColor}`,
                      borderRadius: 4,
                      width: "100%",
                      marginBottom: 2,
                      color: `${categoryProps?.categoryPrimaryColor}`,
                      backgroundColor: `${categoryProps?.categorySecondaryColor}`,
                    }}
                    onClick={() => dispatch(removeResponse(item._id))}
                  >
                    <Typography variant="body1">{item.label}</Typography>
                  </ListItem>
                ) : item.applicable_gender === 1 ||
                  getItemGender(item.applicable_gender) ===
                  userDetails?.gender.toLowerCase() ? (
                  <ListItem
                    key={item._id}
                    sx={{
                      border: `1px solid #E1E1E1`,
                      borderRadius: 4,
                      width: "100%",
                      marginBottom: 2,
                    }}
                    onClick={() => dispatch(addResponse(item._id))}
                  >
                    <Typography variant="body1">{item.label}</Typography>
                  </ListItem>
                ) : null;
              })}
          </List>
          {/* </Scrollbar> */}
        </CardContent>
      );
    }
  };

  if (!question) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          px: 3,
          py: 3,
          gap: 2,
          backgroundColor: "white",
        }}
      >
        <Skeleton animation="pulse" variant="rounded" height={60}></Skeleton>
        <Skeleton animation="pulse" variant="rounded" height={400}></Skeleton>
        <Skeleton animation="pulse" variant="rounded" height={50}></Skeleton>
      </Box>
    );
  }

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "55vh",
          border: "1px solid #E5E5E5",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            marginTop: -4,
            display: "flex",
            justifyContent: "center",
            width: "88%",
          }}
        >
          <Box sx={{ borderRadius: "50%" }}>
            <Box
              sx={{
                background: `${categoryProps?.categoryPrimaryColor}`,
                borderRadius: "50%",
                p: 0.3,
              }}
            >
              <Avatar
                variant="circular"
                sx={{
                  backgroundColor: `${categoryProps?.categoryPrimaryColor}`,
                  width: 60,
                  height: 60,
                  border: "3px solid white",
                }}
              >
                {categoryProps?.categoryIcon}
              </Avatar>
            </Box>
          </Box>
        </Box>
        <CardHeader
          title={question?.question}
          sx={{ paddingTop: 5, textAlign: "center", paddingBottom: 0 }}
        />
        {question?.field_type === "checkbox" ? (
          <Typography
            px={2}
            variant="body1"
            textAlign={"center"}
            color={"#8F8F8F"}
          >
            Select all that applies
          </Typography>
        ) : null}
        {question?.field_type === "input" && question.options?.length > 1 ? (
          <Box sx={{
            borderRadius: "0",
            background: "#FBE6E1",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <QuestionMark />
            <Button
              variant="text"
              sx={{
                py: 0.5,
                textAlign: "center",
                color: "#E56649",
                cursor: "pointer",
              }}
              onClick={() => setStatsModel(true)}
            >
              What are these values?
            </Button>
          </Box>
        ) : null}
        {cardContent()}
        <CardActions
          sx={{
            justifyContent: "space-between",
            p: 1.5,
          }}
        >
          {questionNumber > 0 ? (
            <Button
              variant="text"
              sx={{ color: "black" }}
              fullWidth
              startIcon={<ArrowBackIosNewIcon />}
              disabled={questionNumber <= 0 || loading}
              onClick={() => dispatch(changeQuestion(-1))}
            >
              Previous
            </Button>
          ) : null}
          {question?.field_type === "input" &&
            question.options?.length > 1 ? null : (
            <LoadingButton
              variant="contained"
              fullWidth
              loading={loading}
              sx={{ backgroundColor: "#494E9D", fontSize: "14px" }}
              size="large"
              disabled={
                question?.field_type === "input"
                  ? inputValue?.length < 1
                  : question?.selected_options.length <= 0
              }
              onClick={handleNext}
            >
              {isLast
                ? lastJourney
                  ? "End Assessment"
                  : "Next Dimension"
                : "Next"}
            </LoadingButton>
          )}
        </CardActions>
      </Card>
      <DimensionModel
        open={dimensionModel}
        setOpen={setDimensionModel}
        setLoading={setLoading}
        lastData={lastData}
      />
      <StatsModel open={statsModel} setOpen={setStatsModel} />
      {openDrumRoll &&
        <DrumRollModel open={openDrumRoll} setOpen={setOpenDrumRoll} inputData={inputData} min={question?.options[0]?.min_value} max={question?.options[0]?.max_value} unit={measurementUnit} handleInputValue={handleInputValue} />
      }
    </>
  );
};

export default QuestionsContainer;

import { Box, styled } from "@mui/material";

export const HomeContainer = styled(Box)(
    ({ theme }) => `
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;
        height: 100vh;
        grid-gap: ${theme.spacing(2)};
    `
)

import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";

export const ModalCloseIcon = styled(CloseIcon)`
background-color: White;
border-radius: 20px;
margin-top: -15px;
height: 36.25px;
width: auto;
border: 2px solid #e4dddd;
`;
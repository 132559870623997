import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon, ModalCloseIcon } from "../styled";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  getCurrentCategoryId,
  isLastJourney,
} from "../../store/features/questions";
import { steps } from "../../services/CategoriesData";
import { useNavigate } from "react-router-dom";
import { usePostAnswersMutation } from "../../store/features/api";
import LoadingButton from "@mui/lab/LoadingButton";

interface IDimensionModel {
  open: boolean;
  setOpen: (open: boolean) => void;
  setLoading: (load: boolean) => void;
  lastData: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DimensionModel: React.FC<IDimensionModel> = ({
  open,
  setOpen,
  setLoading,
  lastData,
}): React.ReactElement => {
  const lastJourney = useSelector((state: RootState) => isLastJourney(state));
  const navigate = useNavigate();
  const currentCategory = useSelector((state: RootState) =>
    getCurrentCategoryId(state)
  );
  const categoryProps = steps.find(
    (step) => step.categoryId === currentCategory?.category_key
  );
  const [postAnswers] = usePostAnswersMutation();
  const [dimensionLoader, setDimensionLoader] = React.useState<boolean>(false);

  const handleDimension = () => {
    setDimensionLoader(true);
    if (lastJourney) {
      postAnswers(lastData)
        .then((data: any) => {
          if (data?.data?.status_code === 200) {
            setLoading(false);
            setDimensionLoader(false);
            navigate("/success");
          }
        })
        .catch((err) => console.log(err));
    } else {
      setDimensionLoader(false);
      navigate("/success");
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setOpen(false);
        setLoading(false);
      }}
      PaperProps={{ style: { margin: "0px" } }}
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        },
      }}
    >
      <DialogActions
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <CloseIcon>
          <ModalCloseIcon
            data-testid="modal-close"
            onClick={() => {
              setOpen(false);
              setLoading(false);
            }}
          />
        </CloseIcon>
      </DialogActions>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <img src={categoryProps?.PopUpImage} alt="Leaving Early"></img>
        </Box>
        <Typography variant="h4" color={"#494E9D"} align="center">
          {lastJourney
            ? "Are you sure you want to end assesement?"
            : "Advancing to next dimension"}
        </Typography>
        <Typography variant="h6" color={"#6F6F6F"} align="center">
          This will restrict you from modifying data in the current dimension.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Button
          variant="outlined"
          fullWidth
          disabled={dimensionLoader}
          sx={{ color: "#494E9D" }}
          onClick={() => {
            setOpen(false);
            setLoading(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          fullWidth
          loading={dimensionLoader}
          sx={{ backgroundColor: "#494E9D" }}
          onClick={() => handleDimension()}
        >
          {lastJourney ? "End Assessment" : "Next Dimension"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DimensionModel;

import React from "react";
import { Box, Typography } from "@mui/material";
import CelebrationImage from "../../utils/images/Celebration.png";
import { useDispatch, useSelector } from "react-redux";
import {
  changeJourney,
  getCurrentCategoryId,
  isLastJourney,
  resetQuestions,
  setQuestionFromWorkflow,
} from "../../store/features/questions";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import {
  updateAllProgress,
  resetProgress,
} from "../../store/features/progress";

const SuccessScreen: React.FC = (): React.ReactElement => {
  const lastJourney = useSelector((state: RootState) => isLastJourney(state));
  const journey = useSelector(
    (state: RootState) => state.questions.questionJourney
  );
  const category = useSelector((state: RootState) =>
  getCurrentCategoryId(state)
  );
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      if (lastJourney) {
        dispatch(resetQuestions());
        dispatch(resetProgress());
        navigate("/score");
      } else {
        dispatch(updateAllProgress(journey - 1));
        dispatch(changeJourney(journey + 1));
        dispatch(setQuestionFromWorkflow(0));
        navigate("/dimensions");
      }
    }, 3000);
  }, []);

  return (
    <React.Fragment>
      <Box
        padding={2}
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          justifyContent: "space-evenly",
          marginTop: 10,
        }}
      >
        <Box>
          <img src={CelebrationImage} alt="Hurray"></img>
        </Box>
        <Typography variant="h1" color={"#494E9D"}>
          {lastJourney ? "CONGRATULATIONS!!" : "HURRAY!!"}
        </Typography>
        <Box>
          {lastJourney ? <Typography
            variant="h5"
            color={"#808080"}
            sx={{ textAlign: "center" }}
          >
            You have successfully completed the Health 360 assessment
          </Typography> : 
          <Typography
            variant="h5"
            color={"#808080"}
            sx={{ textAlign: "center" }}
          >
            You have successfully completed <span style={{color: "#494E9D", fontWeight: 700}}>{category?.name}</span> dimension
          </Typography>}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SuccessScreen;

import {
  AwarenessGrayIcon,
  AwarenessWhiteIcon,
  EmotionGrayIcon,
  EmotionWhiteIcon,
  FlagGrayIcon,
  FlagWhiteIcon,
  LifestyleGrayIcon,
  LifestyleWhiteIcon,
  ScreeningIcon,
  StartIcon,
  StatsGrayIcon,
  StatsWhiteIcon,
  HealthGoalIcon,
  ActiveLivingIcon,
  CustomizedHealthIcon,
  DataDrivenIcon,
  DecesionMakingIcon,
  DiseasePreventionIcon,
  EnhancedQualityIcon,
  HealthMaintenanceIcon,
  HolisticIcon,
  MoodStateIcon,
  PersonalisedWellnessIcon,
  ProactiveHealthIcon,
  ProactivePreventionIcon,
  ProactiveScreeningIcon,
  SelfRefelectionIcon,
} from "../../utils";
import AwarenessImage from "../../utils/images/AwarenessIllustration.png";
import EmotionImage from "../../utils/images/EmotionIllustration.png";
import LifestyleImage from "../../utils/images/LifestyleIllustration.png";
import StatsImage from "../../utils/images/StatsIllustration.png";
import ScreeningImage from "../../utils/images/ScreeningIllustration.png";
import AwarenessDimension from "../../utils/images/AwarenessDimension.png";
import EmotionDimension from "../../utils/images/EmotionDimension.png";
import LifestyleDimension from "../../utils/images/LifestyleDimension.png";
import StatsDimension from "../../utils/images/StatsDimension.png";
import ScreeningDimension from "../../utils/images/ScreeningDimension.png";

export type StepData = {
  label: string;
  description: string;
  icon: {
    active: React.ReactNode;
    completed: React.ReactNode;
    disabled: React.ReactNode;
  };
  categoryId: string;
  categoryIcon: React.ReactNode;
  categoryPrimaryColor: string;
  categorySecondaryColor: string;
  longDescription: string;
  overViewImage: string;
  PopUpImage: string;
  dimensionDoes: string;
  keyPoints: KeyPoints[];
  ctaText: string;
  score: number;
  max_score: number;
  score_ranges: ScoreRanges[];
  categoryScoreIcon: React.ReactNode;
};

type KeyPoints = {
  keyIcon: React.ReactNode;
  KeyHeading: string;
  keyDescription: string;
};

type ScoreRanges = {
  _id: string,
  category_id: string,
  minimum_score: number,
  maximum_score: number,
  color: string,
  short_description: string,
  long_description: string
};


export type Recommendations = {
  _id: string;
  score: number;
  title: string;
  description: string;
  category_details: RecommendedCategory[];
};

export type RecommendedCategory = {
  _id: string;
  category_name: string;
  category_key: string;
};

export const steps: StepData[] = [
  {
    label: "Start",
    description: "",
    icon: {
      active: <StartIcon fill="white" stroke="white" width={20} height={20} />,
      completed: (
        <StartIcon fill="white" stroke="white" width={20} height={20} />
      ),
      disabled: (
        <StartIcon fill="#DADADA" stroke="#DADADA" width={20} height={20} />
      ),
    },
    categoryId: "start",
    categoryIcon: <StartIcon />,
    categoryPrimaryColor: "",
    categorySecondaryColor: "",
    longDescription: "",
    overViewImage: "",
    PopUpImage: "",
    dimensionDoes: "",
    keyPoints: [],
    ctaText: "",
    score_ranges: [],
    score: 0,
    max_score: 0,
    categoryScoreIcon: <StartIcon />,
  },
  {
    label: "Awareness",
    description:
      "Assesses awareness of personal health, family history, and medical literacy.",
    icon: {
      active: <AwarenessWhiteIcon width={25} height={25} />,
      completed: <AwarenessWhiteIcon width={25} height={25} />,
      disabled: <AwarenessGrayIcon width={25} height={25} />,
    },
    categoryId: "awareness",
    categoryIcon: <AwarenessWhiteIcon width={30} height={30} />,
    categoryPrimaryColor: "#797EC9",
    categorySecondaryColor: "#F0F1FC",
    longDescription:
      "This dimension gauges an individual's knowledge about their own health, their understanding of healthy and unhealthy behaviors, and their health literacy. It encapsulates an individual's awareness of personal and family medical history, their comprehension of relevant medical statistics, and their knowledge of health resources and services.",
    overViewImage: AwarenessImage,
    PopUpImage: AwarenessDimension,
    dimensionDoes:
      "Assess your understanding of health behaviors, literacy, history, statistics, and resources for informed well-being.",
    keyPoints: [
      {
        KeyHeading: "Informed Decision-Making:",
        keyDescription:
          "Choose a healthy lifestyle with informed decisions on behaviors.",
        keyIcon: <DecesionMakingIcon />,
      },
      {
        KeyHeading: "Proactive Prevention:",
        keyDescription:
          "Use awareness for proactive well-being with preventive measures.",
        keyIcon: <ProactivePreventionIcon />,
      },
      {
        KeyHeading: "Personalized Wellness:",
        keyDescription:
          "Personalize wellness with tailored lifestyle choices for sustainable health.",
        keyIcon: <PersonalisedWellnessIcon />,
      },
    ],
    ctaText: "Test Your Knowledge Now!",
    score: 0,
    max_score: 0,
    score_ranges: [],
    categoryScoreIcon: <AwarenessWhiteIcon width={20} height={20} />,
  },
  {
    label: "Healthy Lifestyle",
    description: "Health is wealth, invest wisely.",
    icon: {
      active: <LifestyleWhiteIcon width={25} height={25} />,
      completed: <LifestyleWhiteIcon width={25} height={25} />,
      disabled: <LifestyleGrayIcon width={25} height={25} />,
    },
    categoryId: "healthy_lifestyle",
    categoryIcon: <LifestyleWhiteIcon width={30} height={30} />,
    categoryPrimaryColor: "#5AC2B9",
    categorySecondaryColor: "#effcfb",
    longDescription:
      "This dimension examines lifestyle choices that impact overall health. It assesses diet, physical activity, sleep patterns, substance use, and adherence to medical treatments.",
    overViewImage: LifestyleImage,
    PopUpImage: LifestyleDimension,
    dimensionDoes:
      "Assess the impact of lifestyle on health, considering diet, activity, sleep, substance use, and treatment adherence.",
    keyPoints: [
      {
        KeyHeading: "Disease Prevention:",
        keyDescription:
          "Reduce disease risk with healthy habits for overall well-being.",
        keyIcon: <DiseasePreventionIcon />,
      },
      {
        KeyHeading: "Active Living:",
        keyDescription:
          "Adopt an active lifestyle for better fitness, mood, energy, and heart health.",
        keyIcon: <ActiveLivingIcon />,
      },
      {
        KeyHeading: "Holistic Well-being:",
        keyDescription:
          "Blend choices for holistic health, acknowledging diet, exercise, sleep, and substance use interconnection.",
        keyIcon: <HolisticIcon />,
      },
    ],
    ctaText: "Take The Assessment",
    score: 0,
    max_score: 0,
    score_ranges: [],
    categoryScoreIcon: <LifestyleWhiteIcon width={20} height={20} />,
  },
  {
    label: "Emotional Wellbeing",
    description:
      "Health360 assesses emotional resilience and stress management for overall well-being",
    icon: {
      active: <EmotionWhiteIcon width={25} height={25} />,
      completed: <EmotionWhiteIcon width={25} height={25} />,
      disabled: <EmotionGrayIcon width={25} height={25} />,
    },
    categoryId: "emotional_wellbeing",
    categoryIcon: <EmotionWhiteIcon width={30} height={30} />,
    categoryPrimaryColor: "#F49198",
    categorySecondaryColor: "#FEF1F2",
    longDescription:
      "This dimension evaluates an individual's emotional resilience and mental well-being, determining how effectively they manage stress and recover from emotional setbacks. This dimension also takes into account the individual's mood states, as emotional health is a critical component of overall well-being.",
    overViewImage: EmotionImage,
    PopUpImage: EmotionDimension,
    dimensionDoes:
      "Evaluate emotional resilience, mental well-being, and mood as critical components of overall health.",
    keyPoints: [
      {
        KeyHeading: "Mood State Optimization:",
        keyDescription:
          "Manage and cultivate positive mood for enhanced mental well-being.",
        keyIcon: <MoodStateIcon />,
      },
      {
        KeyHeading: "Self-Reflection and Awareness:",
        keyDescription:
          "Foster mindfulness for navigating your emotional landscape with self-awareness.",
        keyIcon: <SelfRefelectionIcon />,
      },
      {
        KeyHeading: "Enhanced Quality of Life:",
        keyDescription:
          "Prioritize emotional resilience and mental well-being for a more content and fulfilling life with peace of mind.",
        keyIcon: <EnhancedQualityIcon />,
      },
    ],
    ctaText: "Take The Assessment",
    score: 0,
    max_score: 0,
    score_ranges: [],
    categoryScoreIcon: <EmotionWhiteIcon width={20} height={20} />,
  },
  {
    label: "Health Screening",
    description:
      "Health360 assesses emotional resilience and stress management for overall well-being",
    icon: {
      active: <StatsWhiteIcon width={25} height={25} />,
      completed: <StatsWhiteIcon width={25} height={25} />,
      disabled: <StatsGrayIcon width={25} height={25} />,
    },
    categoryId: "health_screening",
    categoryIcon: (
      <ScreeningIcon width={30} height={30} fill="white" stroke="white" />
    ),
    categoryPrimaryColor: "#B09FE6",
    categorySecondaryColor: "#EFECFA",
    longDescription:
      "This dimension measures an individual's attentiveness to their health needs and preventative care. It also includes assessment of regular health check-ups and screenings.",
    overViewImage: ScreeningImage,
    PopUpImage: ScreeningDimension,
    dimensionDoes:
      "Assess your attentiveness to health needs, preventive care, and regular check-ups/screenings for overall well-being.",
    keyPoints: [
      {
        KeyHeading: "Proactive Preventive Care:",
        keyDescription:
          "Prioritize prevention for proactive health management, avoiding potential issues.",
        keyIcon: <ProactiveScreeningIcon />,
      },
      {
        KeyHeading: "Health Maintenance Routines:",
        keyDescription:
          "Cultivate self-care habits for long-term optimal health and well-being.",
        keyIcon: <HealthMaintenanceIcon />,
      },
      {
        KeyHeading: "Health Goal Alignment:",
        keyDescription:
          "Sync wellness goals with proactive preventative care for a holistic, sustainable lifestyle.",
        keyIcon: <HealthGoalIcon />,
      },
    ],
    ctaText: "Let’s Go!",
    score: 0,
    max_score: 0,
    score_ranges: [],
    categoryScoreIcon: (
      <ScreeningIcon width={20} height={20} fill="white" stroke="white" />
    ),
  },
  {
    label: "Health Stats",
    description:
      "Health360 assesses emotional resilience and stress management for overall well-being",
    icon: {
      active: <FlagWhiteIcon width={25} height={25} />,
      completed: <FlagWhiteIcon width={25} height={25} />,
      disabled: <FlagGrayIcon width={25} height={25} />,
    },
    categoryId: "health_stats",
    categoryIcon: <StatsWhiteIcon width={30} height={30} />,
    categoryPrimaryColor: "#FCBF6C",
    categorySecondaryColor: "#F2F3FF",
    longDescription:
      "This dimension focuses on the monitoring of key health parameters like blood pressure, cholesterol levels, blood sugar, body mass index (BMI) and other health parameters. Regular tracking of these health indicators provides crucial insights into an individual's health status.",
    overViewImage: StatsImage,
    PopUpImage: StatsDimension,
    dimensionDoes:
      "Track health metrics such as blood pressure, cholesterol, blood sugar, BMI, and more for comprehensive monitoring.",
    keyPoints: [
      {
        KeyHeading: "Data-Driven Decision Making:",
        keyDescription:
          "Utilize health data for informed lifestyle, treatment, and prevention decisions.",
        keyIcon: <DataDrivenIcon />,
      },
      {
        KeyHeading: "Customized Health Plans:",
        keyDescription:
          "Personalize health plans using real-time data for tailored wellness strategies.",
        keyIcon: <CustomizedHealthIcon />,
      },
      {
        KeyHeading: "Proactive Health Maintenance:",
        keyDescription:
          "Regular monitoring preserves health, prevents chronic issues, and enhances well-being.",
        keyIcon: <ProactiveHealthIcon />,
      },
    ],
    ctaText: "Monitor Your Vitals",
    score: 0,
    max_score: 0,
    score_ranges: [],
    categoryScoreIcon: <StatsWhiteIcon width={20} height={20} />,
  },
];

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useRef } from "react";
import { Options } from "../../store/features/questions";
import * as yup from "yup";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LoadingButton from "@mui/lab/LoadingButton";
import { Plus } from "../../utils";
interface IMultiInputContainerProps {
  options: Options[];
  handleStats: (values: any) => void;
  primaryColor: string;
  secondaryColor: string;
  loader: boolean;
}

const buildYupValidator = (fields: Options[]) => {
  const yupSchema: { [key: string]: any } = {};
  fields.forEach((field) => {
    if (field.field_type_name === "input" && field.label.toLowerCase() === "blood pressure") {
      yupSchema[field.label] = yup
        .string()
        .matches(
          /^(9[0-9]|[1-2][0-9][0-9])\/([4-9][0-9]|1[0-1][0-9]|120)$/,
          "Value should be between 90-299 / 40-120"
        )
        .required();
    } else if (field.field_type_name === "input") {
      if (field.validation) {
        yupSchema[field.label] = yup
          .number()
          .min(
            field.min_value ?? 0,
            `Value should be between ${field.min_value ?? 0} and ${field.max_value
            }`
          )
          .max(
            field.max_value ?? 0,
            `Value should be between ${field.min_value ?? 0} and ${field.max_value
            }`
          )
          .required();
      } else {
        yupSchema[field.label] = yup.number().required();
      }
    } else if (field.field_type_name === "radio") {
      yupSchema[field.label] = yup.boolean().required();
    }
  });
  return yup.object().shape(yupSchema);
  // .test(
  //   "at-least-two-fields",
  //   "At least two fields are required",
  //   (values) => {
  //     const nonEmptyFields = Object.values(values).filter(
  //       (value) => value !== undefined && value !== ""
  //     );
  //     return nonEmptyFields.length >= 2;
  //   }
  // );
};

const buildYupValidatorForRemaining = (fields: Options[]) => {
  const yupSchema: { [key: string]: any } = {};
  fields.forEach((field) => {
    yupSchema[field.label] = yup.string().required();
  });
  return yup.object().shape(yupSchema);
};

const MultiInputContainer: React.FC<IMultiInputContainerProps> = ({
  options,
  handleStats,
  primaryColor,
  secondaryColor,
  loader,
}): React.ReactElement => {
  const [questionOptions, setQuestionOptions] =
    React.useState<Options[]>(options);
  const [selectedOptions, setSelectedOptions] = React.useState<Options[]>([]);
  const [selectionCompleted, setSelectionCompleted] =
    React.useState<boolean>(false);
  const [formValues, setFormValues] = React.useState<any>([]);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const formikRef = useRef<any>(null);

  const generateInitialValues = (fields: Options[], haveValue: boolean) => {
    const initialValues = {} as { [key: string]: string | boolean };

    fields.forEach((field) => {
      if (field.field_type_name === "input") {
        initialValues[field.label] = haveValue
          ? formValues[field.label] ?? ""
          : "";
      } else if (field.field_type_name === "radio") {
        initialValues[field.label] = haveValue
          ? formValues[field.label] ?? false
          : "";
      }
    });

    return initialValues;
  };

  const getChips = () => {
    const handleValidateForm = () => {
      if (formikRef.current) {
        setTimeout(() => {
          formikRef.current.validateForm().then((errors: object) => {
            if (Object.keys(errors).length === 0) {
              console.log(Object.keys(errors));
            }
          });
        }, 100);
      }
    }
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, paddingTop: 2 }}>
        {questionOptions.map((item, index) => (
          <Chip
            key={index}
            sx={{ backgroundColor: `${secondaryColor}` }}
            avatar={
              <Avatar sx={{ backgroundColor: "#494E9D" }}>
                <Plus />
              </Avatar>
            }
            onClick={() => {
              setSelectedOptions((oldArray) => [...oldArray, item]);
              const temp = [...questionOptions];
              temp.splice(index, 1);
              setQuestionOptions(temp);
              handleValidateForm();
            }}
            label={item.label}
          ></Chip>
        ))}
      </Box>
    );
  };

  return (
    <>
      {selectionCompleted ? (
        <>
          <Typography
            variant="body2"
            sx={{ color: "#bbbbbb", fontSize: "12px" }}
          >
            *For the remaining parameters, do you know whether they are
            normal/abnormal or you don’t know
          </Typography>
          <Formik
            initialValues={generateInitialValues(questionOptions, false)}
            validationSchema={buildYupValidatorForRemaining(questionOptions)}
            onSubmit={(values) => handleStats({ ...formValues, ...values })}
          >
            {({ handleSubmit, isValid, dirty, values, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                {questionOptions?.map((option, index) => (
                  <Box my={1.5} key={`${index}-${option.label}`}>
                    <Typography color={"#2F3265"} sx={{
                      fontFamily: "Nunito Sans",
                      fontSize: "13px",
                      fontWeight: 600,
                      letterSpacing: "0.15px",
                    }}>{option.label}</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        border: "0.7px solid #494E9D4D",
                        borderRadius: "4px",
                        height: "7vh"
                      }}
                    >
                      <ListItem
                        sx={
                          values[option.label] === "normal"
                            ? {
                              background: secondaryColor,
                              color: "#494E9D",
                              fontFamily: "Nunito Sans",
                              fontSize: "14px",
                              fontWeight: 700,
                              letterSpacing: "0.04em"
                            }
                            : {
                              color: "#616161",
                              fontFamily: "Nunito Sans",
                              fontSize: "14px",
                              fontWeight: 400,
                              letterSpacing: "0.04em"
                            }
                        }
                        onClick={() => setFieldValue(option.label, "normal")}
                      >
                        Normal
                      </ListItem>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ background: "#494E9D4D" }}
                      />
                      <ListItem
                        sx={
                          values[option.label] === "abnormal"
                            ? {
                              background: secondaryColor,
                              color: "#494E9D",
                              fontFamily: "Nunito Sans",
                              fontSize: "14px",
                              fontWeight: 700,
                              letterSpacing: "0.04em"
                            }
                            : {
                              color: "#616161",
                              fontFamily: "Nunito Sans",
                              fontSize: "14px",
                              fontWeight: 400,
                              letterSpacing: "0.04em"
                            }
                        }
                        onClick={() => setFieldValue(option.label, "abnormal")}
                      >
                        Abnormal
                      </ListItem>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ background: "#494E9D4D" }}
                      />
                      <ListItem
                        sx={
                          values[option.label] === "dont-know"
                            ? {
                              background: secondaryColor,
                              color: "#494E9D",
                              fontFamily: "Nunito Sans",
                              fontSize: "14px",
                              fontWeight: 700,
                              letterSpacing: "0.04em"
                            }
                            : {
                              color: "#616161",
                              fontFamily: "Nunito Sans",
                              fontSize: "14px",
                              fontWeight: 400,
                              letterSpacing: "0.04em"
                            }
                        }
                        onClick={() => setFieldValue(option.label, "dont-know")}
                      >
                        Don't Know
                      </ListItem>
                    </Box>
                    {index !== questionOptions.length - 1 && <Divider sx={{ mt: 1.9, mx: 1.5, mb: 2.3 }}></Divider>}
                  </Box>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    my: 1,
                  }}
                >
                  <Button
                    variant="text"
                    size="medium"
                    sx={{ color: "black", px: 0 }}
                    fullWidth
                    startIcon={<ArrowBackIosNewIcon />}
                    disabled={loader}
                    onClick={() => setSelectionCompleted(false)}
                  >
                    Previous
                  </Button>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    loading={loader}
                    sx={{ backgroundColor: "#494E9D", py: 1 }}
                    disabled={!dirty || !isValid}
                    size="medium"
                    type="submit"
                  >
                    End Assessment
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      ) : null
      }
      {
        !selectionCompleted ? (
          <>
            {/* {selectedOptions.length ? ( */}
            <Formik
              innerRef={formikRef}
              initialValues={generateInitialValues(selectedOptions, true)}
              validationSchema={buildYupValidator(selectedOptions)}
              onSubmit={(values) => {
                if (selectedOptions.length === options.length) {
                  setFormValues(values);
                  handleStats(values);
                } else {
                  setIsDirty(true);
                  setFormValues(values);
                  setSelectionCompleted(true);
                }
              }}
            >
              {({ handleSubmit, isValid, dirty, validateForm }) => (
                <Form onSubmit={handleSubmit}>
                  {selectedOptions?.map((option, index) => (
                    <FormControl key={option._id} fullWidth>
                      {option.field_type_name === "radio" ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            py: 1,
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6">{option.label}</Typography>
                          <Field
                            as={Checkbox}
                            name={option.label}
                            id={option.label}
                            variant="outlined"
                            size="small"
                          />
                        </Box>
                      ) : (
                        <>
                          <Box py={1}>
                            <Typography variant="h6">{option.label}</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Field
                                name={option.label}
                                id={option.label}
                                size="small"
                              >
                                {({ field }: { field: any }) => (
                                  <TextField
                                    {...field}
                                    sx={{ width: 250, flex: 1 }}
                                    size="small"
                                    dir="ltr"
                                    placeholder={
                                      option.label.toLowerCase() === "blood pressure" ? "eg: 120/80" : ""
                                    }
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {option.unit_of_mesaurement}
                                        </InputAdornment>
                                      ),
                                    }}
                                    variant="outlined"
                                  />
                                )}
                              </Field>
                              <IconButton
                                onClick={() => {
                                  setQuestionOptions((oldArray) => [
                                    ...oldArray,
                                    option,
                                  ]);
                                  const temp = [...selectedOptions];
                                  temp.splice(index, 1);
                                  setSelectedOptions(temp);
                                  setTimeout(() => {
                                    validateForm().then((errors: any) => {
                                      console.log(errors);
                                    });
                                  }, 100)
                                }}
                              >
                                <RemoveCircleOutlineIcon
                                  sx={{ color: "#494E9D" }}
                                  fontSize="large"
                                />
                              </IconButton>
                            </Box>
                          </Box>
                          <ErrorMessage name={option.label}>
                            {(msg) => (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </>
                      )}
                    </FormControl>
                  ))}
                  {getChips()}
                  <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    my: 1,
                  }}>
                    <Button
                      variant="outlined"
                      sx={{
                        my: 2,
                        width: "45%",
                        '&.Mui-disabled': {
                          color: 'lightgrey',
                          border: '1px solid lightgrey'
                        }
                      }}
                      disabled={selectedOptions.length > 0} //selected options length is NOT 0
                      size="medium"
                      onClick={() => {
                        setSelectionCompleted(true);
                      }}
                    >
                      Don't Know
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#494E9D",
                        my: 2,
                        width: "45%",
                        '&.Mui-disabled': {
                          color: 'white',
                          background: 'lightgrey'
                        }
                      }}
                      disabled={
                        !isValid ||
                        !selectedOptions.length ||
                        (isDirty === false ? !dirty : false)
                      }
                      size="medium"
                      type="submit"
                    >
                      {selectedOptions.length === options.length
                        ? "End Assessment"
                        : "Next"}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
            {/* ) : (
            getChips()
          )} */}
          </>
        ) : null
      }
    </>
  );
};

export default MultiInputContainer;

import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import OnBoardingImage from "../../utils/images/Initial.png";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../components/LogoutModal";
import Health360Image from "../../utils/images/FirstImage.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Login from "@mui/icons-material/Login";
import { StopWatch } from "../../utils";

interface IOnBoardingPage {
  completed: boolean;
  demographics: boolean;
}

const OnBoardingPage: React.FC<IOnBoardingPage> = ({
  completed,
  demographics,
}): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const progress = useSelector((state: RootState) => state.progress.progress);
  React.useEffect(() => {
    if (completed) {
      navigate("/score");
    }
  }, [completed]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100vh",
          paddingTop: 6,
          backgroundImage: `url(${OnBoardingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box sx={{
          display: "flex",
          justifyContent: "flex-end",
        }} padding={2}>
          <IconButton onClick={() => setOpen(true)}>
            <Login sx={{ color: "gray" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img src={Health360Image} alt="Health 360"></img>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                textAlign={"center"}
                variant="h5"
                sx={{ backgroundColor: "#f29ba1", width: 100, color: "white" }}
              >
                Health 360
              </Typography>
            </Box>
            <Typography variant="h3" textAlign={"center"} px={2}>
              Dive into Health Journey
            </Typography>
            <Typography variant="h6" textAlign={"center"} px={2}>
              Health360, a dynamic health index, monitors individuals in
              real-time across five dimensions: Awareness, Lifestyle, Stats,
              Emotional Wellbeing, and Screening. It empowers better decisions
              for fostering healthier habits.
            </Typography>
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1vh"
            }}>
              <Box sx={{
                display: "flex",
                background: "#FFFFFF",
                justifyContent: "center",
                alignItems: "center",
                gap: "3%",
                padding: "7px",
                width: "210px",
                borderRadius: "27px"
              }}>
                <StopWatch />
                <Typography sx={{
                  fontFamily: "Nunito Sans",
                  fontSize: "12px",
                  fontWeight: "700",
                  color: "#494E9D"
                }}>Approx. 10 min to complete</Typography>
              </Box>
            </Box>
          </Box>
          <Box px={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate("/overview")}
              sx={{ backgroundColor: "White", color: "#494E9D", '&:hover': { color: "white" } }}
              fullWidth
            >
              What is Health360?
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                demographics
                  ? navigate("/demographics")
                  : navigate("/dimensions")
              }
              fullWidth
            >
              {progress[0] > 1 ? "Resume" : "Begin"} Assessment
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <ExitModel open={open} setOpen={setOpen} exit={true} />
       */}
      <LogoutModal open={open} setOpen={setOpen} />
    </React.Fragment>
  );
};

export default OnBoardingPage;

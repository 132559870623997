import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
  styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Category } from "../../store/features/questions";
import { steps } from "../../services/CategoriesData";

interface ProgressContainerProps {}

type CustomStepIconProps = {
  icon: {
    active: React.ReactNode;
    completed: React.ReactNode;
    disabled: React.ReactNode;
  };
  status: "active" | "completed" | "disabled";
};

const CustomLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 2,
  "& .MuiLinearProgress-colorPrimary": {
    backgroundColor: "#666bb8",
  },
  "& .MuiLinearProgress-bar": {
    borderRadius: 2,
    backgroundColor: "rgb(28 54 130 / 39%)",
  },
}));

const ProgressContainer: React.FC<
  ProgressContainerProps
> = (): React.ReactElement => {
  const progress = useSelector((state: RootState) => state.progress.progress);
  const journey = useSelector(
    (state: RootState) => state.questions.questionJourney
  );
  const categories = useSelector(
    (state: RootState) => state.questions.categories
  );
  const getStatus = (stepIndex: number, activeStep: number) => {
    if (stepIndex === activeStep) {
      return "active";
    } else if (stepIndex < activeStep) {
      return "completed";
    } else {
      return "disabled";
    }
  };
  const CustomStepIcon: React.FC<CustomStepIconProps> = ({ icon, status }) => {
    let bgColour = "linear-gradient(to right, #fcc06c 0%, #ff9499 100%)";
    let borderColor = "none";
    if (status === "completed") {
      bgColour = "linear-gradient(to right, #83d2d8 0%, #666bb8 100%)";
      borderColor = "none";
    } else if (status === "disabled") {
      bgColour = "none";
      borderColor = "1px solid #DADADA";
    }
    return (
      <Avatar
        sx={{
          width: 40,
          height: 40,
          background: bgColour,
          border: borderColor,
        }}
      >
        {icon[status]}
      </Avatar>
    );
  };

  const progressContents = (category: Category, index: number) => {
    const catg = steps.find(
      (step) => step.categoryId === category.category_key
    );
    if (catg) {
      return (
        <React.Fragment key={index}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              alignItems: "center",
            }}
            height={56}
          >
            <CustomStepIcon
              icon={catg.icon}
              status={getStatus(index + 1, journey)}
            ></CustomStepIcon>
            <Typography
              fontSize={7}
              fontWeight={600}
              color={
                getStatus(index, journey) === "disabled" ? "#DADADA" : "#3D3D3D"
              }
              textAlign={"center"}
            >
              {category.name}
            </Typography>
          </Box>
          {index !== categories.length - 1 ? (
            <Box width={"100%"} paddingBottom={2}>
              <CustomLinearProgress
                variant="determinate"
                value={progress[index + 1]}
              />
              <div></div>
            </Box>
          ) : null}
        </React.Fragment>
      );
    } else return null;
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography variant={"h4"}>{steps[journey].label}</Typography>
            <Typography variant={"h6"} color={"rgba(84, 84, 84, 0.46)"}>
              {steps[journey].description}
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" alignItems="center" marginBottom={2}>
            <React.Fragment key={"start"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  alignItems: "center",
                }}
                height={56}
              >
                <CustomStepIcon
                  icon={steps[0].icon}
                  status={getStatus(-1, journey)}
                ></CustomStepIcon>
                <Typography
                  fontSize={7}
                  fontWeight={600}
                  color={
                    getStatus(-1, journey) === "disabled"
                      ? "#DADADA"
                      : "#3D3D3D"
                  }
                  textAlign={"center"}
                >
                  {steps[0].label}
                </Typography>
              </Box>
              <Box width={"100%"} paddingBottom={2}>
                <CustomLinearProgress
                  variant="determinate"
                  value={progress[0]}
                />
                <div></div>
              </Box>
            </React.Fragment>
            {categories.map((category, index) =>
              progressContents(category, index)
            )}
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default ProgressContainer;
import React from "react";
import { CustomScoreSVG, InsightsModel, RangeModel } from "../../components";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Skeleton,
  Typography,
  circularProgressClasses,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetAuthenticateQuery,
  useGetTotalScoreQuery,
  usePostRetakeSurveyMutation,
} from "../../store/features/api";
import { GoldCircle, GoldPlus } from "../../utils";
import LoosePointsImage from "../../utils/images/LoosePointsImage.png";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Scrollbar from "../../components/Scrollbar";
import {
  Recommendations,
  RecommendedCategory,
  StepData,
  steps,
} from "../../services/CategoriesData";
import ClearIcon from "@mui/icons-material/Clear";
import LogoutModal from "../../components/LogoutModal";
import Login from "@mui/icons-material/Login";
import { usePostFinalReportMutation } from "../../store/features/api";
import { toast } from "react-toastify"
interface Score {
  awareness: number;
  lifestyle: number;
  healthScreening: number;
  healthStats: number;
  emotion: number;
  overallScore: number;
}

interface MaxScore {
  awareness: number;
  lifestyle: number;
  healthScreening: number;
  healthStats: number;
  emotion: number;
}

interface LostMyPoints {
  _id: string;
  score: number;
  description: string;
}

const initialScore: Score = {
  awareness: 0,
  lifestyle: 0,
  healthScreening: 0,
  healthStats: 0,
  emotion: 0,
  overallScore: 0,
};

const initialMaxScore: MaxScore = {
  awareness: 0,
  lifestyle: 0,
  healthScreening: 0,
  healthStats: 0,
  emotion: 0,
};

const categoryKeyMapping: Record<string, keyof Score> = {
  awareness: "awareness",
  healthy_lifestyle: "lifestyle",
  emotional_wellbeing: "emotion",
  health_stats: "healthStats",
  health_screening: "healthScreening",
};

const ScoreCard: React.FC = (): React.ReactElement => {
  const { data: scoreData, isLoading } = useGetTotalScoreQuery("");
  const {
    data: userData,
    isLoading: userLoading,
    refetch: authRefetch,
  } = useGetAuthenticateQuery({
    svaas_user_id: "",
    age: 0,
    gender: "",
  });
  const [retakeSurvey, { isLoading: retakeSurveyLoading }] = usePostRetakeSurveyMutation();
  const [score, setScore] = React.useState<Score>(initialScore);
  const [maxScore, setMaxScore] = React.useState<MaxScore>(initialMaxScore);
  const [categoryData, setCategoryData] = React.useState<StepData[]>([]);
  const [rangeModelOpen, setRangeModelOpen] = React.useState<boolean>(false);
  const [insightsModelOpen, setInsightsModelOpen] =
    React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [postFinalReport, { isLoading: sendReportLoading }] = usePostFinalReportMutation();
  const user_details = userLoading ? {} : userData?.data?.user_details;
  React.useEffect(() => {
    if (scoreData && !isLoading) {
      scoreData.category_scores.forEach((category: any) => {
        const categoryKey = categoryKeyMapping[category?.category_key];
        if (categoryKey) {
          setScore((prevScore) => ({
            ...prevScore,
            [categoryKey]: category?.score,
            overallScore: scoreData?.total_score,
          }));
          setMaxScore((prevMaxScore) => ({
            ...prevMaxScore,
            [categoryKey]: category?.max_score,
          }));
        }
      });
      const filteredArr = steps
        .map((obj2) => {
          const filteredObject = scoreData?.category_scores.find(
            (obj1: any) => obj1?.category_key === obj2.categoryId
          );
          return filteredObject
            ? {
              ...obj2,
              score: filteredObject.score,
              max_score: filteredObject.max_score,
              score_ranges: filteredObject.score_ranges || [],
            }
            : obj2;
        })
        .filter(
          (obj3) =>
            scoreData?.category_scores.some(
              (obj4: any) => obj4?.category_key === obj3.categoryId
            )
        );
      setCategoryData(filteredArr);
    }
  }, [scoreData, isLoading]);
  const handleRetake = () => {
    retakeSurvey({ create_new_survey: true })
      .then((data: any) => {
        if (data?.data?.status_code === 200) {
          window.location.href = `${process.env.REACT_APP_HOME_SCREEN_REDIRECT_URL}`;
        }
      })
      .catch((err: any) => {
        toast.error(err);
      });
  };
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          px: 3,
          py: 7,
          gap: 2,
        }}
      >
        <Skeleton variant="rounded" height={100}></Skeleton>
        <Skeleton variant="rounded" height={300}></Skeleton>
        <Skeleton variant="rounded" height={400}></Skeleton>
      </Box>
    );
  }

  const getCategoryCard = (categoryProps: StepData, index: number) => {
    const scoreProperties = categoryProps.score_ranges.find(
      (range: any) =>
        categoryProps.score >= range.minimum_score && categoryProps.score <= range.maximum_score
    );
    return (
      <Card
        key={index}
        sx={{ border: "2px solid #E8E8E8", p: 2, my: 1, boxShadow: "none" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              marginBottom: 1,
            }}
          >
            <Box>
              <Box sx={{ borderRadius: "50%" }}>
                <Box
                  sx={{
                    background: `${categoryProps?.categoryPrimaryColor}`,
                    borderRadius: "50%",
                    p: 0.3,
                    width: 40,
                    height: 40,
                  }}
                >
                  <Avatar
                    variant="circular"
                    sx={{
                      backgroundColor: `${categoryProps?.categoryPrimaryColor}`,
                      width: 35,
                      height: 35,
                      border: "3px solid white",
                    }}
                  >
                    {categoryProps?.categoryScoreIcon}
                  </Avatar>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography variant="h5" fontWeight={700}>
                {categoryProps.label}
              </Typography>
              <Typography variant="body1" fontSize={10} fontWeight={400}>
                {scoreProperties?.short_description}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: -2,
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: "white",
                border: `1px solid ${scoreProperties?.color}`,
                marginRight: -1.5,
              }}
            >
              <GoldCircle
                style={{ position: "absolute" }}
                width={20}
                height={20}
              />
              <GoldPlus style={{ position: "absolute" }} />
            </Box>
            <Box
              sx={{
                width: 62,
                height: 25,
                backgroundColor: `${scoreProperties?.color}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                p: 1,
              }}
            >
              <Typography color={"white"} fontSize={10} fontWeight={800}>
                {categoryProps?.score}/{categoryProps?.max_score}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography fontWeight={400} fontSize={12}>
          {scoreProperties?.long_description}
        </Typography>
      </Card>
    );
  };
  const handleSendReport = () => {
    postFinalReport({ generate_pdf: true }).then((res: any) => {
      let status = res?.data?.status.toLowerCase() || "error";
      let message = res?.data?.message || "Error in sending report to server";
      if (status === "success") {
        toast.success(message);
      } else {
        toast.error(message);
      }
    }).catch((err) => {
      toast.error(err)
    });
  }
  return (
    <React.Fragment>
      {" "}
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <Scrollbar>
          <Box
            sx={{
              background:
                "linear-gradient(90deg, rgba(233,255,252,1) 1%, rgba(220,223,241,1) 100%);",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
            }}
            height={120}
          >
            <Typography variant="h4" color={"#313131"} fontSize={20}>
              Insights & Overview
            </Typography>
            <IconButton
              size="large"
              sx={{ color: "#494E9D" }}
              onClick={() => setOpen(true)}
            >
              <Login />
            </IconButton>
          </Box>
          <Box
            flexGrow={0}
            sx={{
              display: "flex",
              marginTop: -4,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              background:
                "linear-gradient(90deg, rgba(245,144,151,1) 10%, rgba(79,80,158,1) 100%)",
            }}
            px={2}
            py={1}
            mx={2}
            borderRadius={1}
          >
            <Box>
              <Typography variant="h4" color={"white"}>
                Health 360
              </Typography>
              <Typography variant="body1" color={"white"}>
                Your Score is{" "}
                <span style={{ fontSize: "12px", fontWeight: 900 }}>
                  {scoreData?.status_key.toUpperCase()}
                </span>
              </Typography>
              <Button
                sx={{
                  backgroundColor: "#FFCC00",
                  color: "#9F7430",
                  marginTop: 1,
                  borderRadius: 26,
                  height: 20,
                }}
                size="small"
                onClick={() => setRangeModelOpen(true)}
              >
                Know More
              </Button>
            </Box>
            <Box>
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  my: 1,
                }}
              >
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: "#FFFFFF",
                  }}
                  size={50}
                  thickness={4}
                  value={100}
                />
                <CircularProgress
                  variant="determinate"
                  disableShrink
                  sx={{
                    color: "#FFCC00",
                    animationDuration: "550ms",
                    position: "absolute",
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                  size={50}
                  thickness={4}
                  value={(scoreData?.total_score / 360) * 100}
                />
                <GoldCircle
                  style={{ position: "absolute" }}
                  width={25}
                  height={25}
                />
                <GoldPlus style={{ position: "absolute" }} />
              </Box>
              <Typography variant="h6" color={"white"}>
                {scoreData?.total_score}/360
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }} py={2}>
            <CustomScoreSVG score={score} maxScore={maxScore}></CustomScoreSVG>
          </Box>
          {/* <Card
       sx={{
         backgroundColor: "#F0F1FC",
         mx: 2,
         border: "1px solid #494E9D",
         my: 2,
       }}
     >
       <Grid container>
         <Grid item xs={5}>
           <Box
             padding={2}
             sx={{
               display: "flex",
               flexDirection: "column",
               gap: 2,
               py: 4,
             }}
           >
             <Avatar variant="rounded" sx={{ backgroundColor: "#494E9D" }}>
               <img src={medalImg} />
             </Avatar>
             <Typography variant="h4" fontSize={15} color={"#313131"}>
               My Overview
             </Typography>
           </Box>
         </Grid>
         <Grid item xs={7}>
           <Box
             m={1}
             height={140}
             sx={{
               backgroundImage: `url(${FullDimension})`,
               backgroundRepeat: "no-repeat",
               backgroundSize: "100% 100%",
               backgroundPosition: "center",
             }}
           ></Box>
         </Grid>
         <CardContent
           sx={{
             backgroundColor: "white",
             p: 0,
             paddingLeft: 3,
             paddingBottom: 0,
           }}
         >
           <List sx={{ listStyleType: "disc" }}>
             <ListItem sx={{ display: "list-item", p: 0 }}>
               <Typography>
                 Lorem ipsum dolor sit amet consectetur adipisicing elit.
                 Maxime consectetur voluptatum, quis doloribus dicta
                 sapiente!
               </Typography>
             </ListItem>
             <ListItem sx={{ display: "list-item", p: 0 }}>
               <Typography>
                 Lorem ipsum dolor sit amet consectetur.
               </Typography>
             </ListItem>
             <ListItem sx={{ display: "list-item", p: 0 }}>
               <Typography>
                 Lorem ipsum dolor sit amet, consectetur adipisicing
                 eListItemt. Qui, est.
               </Typography>
             </ListItem>
             <ListItem sx={{ display: "list-item", p: 0 }}>
               <Typography>
                 Lorem ipsum dolor sit amet consectetur adipisicing elit.
                 Voluptatibus, cumque dicta!
               </Typography>
             </ListItem>
           </List>
         </CardContent>
       </Grid>
     </Card> */}
          <Box m={2}>
            <Typography variant="h4" fontSize={18}>
              My Score Details
            </Typography>
            {categoryData.map((categoryProps, index) =>
              getCategoryCard(categoryProps, index)
            )}
          </Box>
          {scoreData?.lost_my_points.length > 0 ? (
            <Card
              sx={{
                backgroundColor: "#fff2f3",
                m: 2,
                border: "1px solid #F49198",
                boxShadow: "none",
              }}
            >
              <Grid container>
                <Grid item xs={5}>
                  <Box
                    padding={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      py: 4,
                    }}
                  >
                    <Avatar
                      variant="rounded"
                      sx={{ backgroundColor: "#F49198" }}
                    >
                      <QuestionMarkIcon sx={{ color: "white" }} />
                    </Avatar>
                    <Typography variant="h4" fontSize={15} color={"#313131"}>
                      Where did I lose points?
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Box
                    m={1}
                    height={140}
                    sx={{
                      backgroundImage: `url(${LoosePointsImage})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                      backgroundPosition: "center",
                    }}
                  ></Box>
                </Grid>
                <CardContent
                  sx={{
                    backgroundColor: "white",
                    p: 0.5,
                    width: "100%",
                    " &.MuiCardContent-root:last-child": {
                      paddingBottom: 1,
                    },
                  }}
                >
                  <List sx={{ listStyleType: "disc" }}>
                    {scoreData.lost_my_points.map((points: LostMyPoints) => (
                      <ListItem
                        key={points._id}
                        disablePadding
                        sx={{ py: 0.3 }}
                      >
                        <ListItemIcon sx={{ minWidth: 35 }}>
                          <ClearIcon sx={{ color: "#F49198" }} />
                        </ListItemIcon>
                        <Typography>{points.description}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Grid>
            </Card>
          ) : null}
          {scoreData?.recommendations.length > 0 ? (
            <Box m={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4" fontSize={18}>
                  How can I improve my score?
                </Typography>
                <Button
                  variant="text"
                  sx={{ textDecoration: "underline" }}
                  onClick={() => setInsightsModelOpen(true)}
                >
                  View All
                </Button>
              </Box>
              {scoreData?.recommendations
                .slice(0, 5)
                .map((categoryProps: Recommendations) => (
                  <Card
                    key={categoryProps._id}
                    sx={{
                      border: "2px solid #E3E4F9",
                      p: 2,
                      my: 1,
                      boxShadow: "none",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          marginBottom: 1,
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h4">
                            {categoryProps.title}
                          </Typography>
                          {categoryProps.score > 0 ?
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginRight: -2,
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: 30,
                                  height: 30,
                                  borderRadius: "50%",
                                  backgroundColor: "white",
                                  border: "1px solid #72D65A",
                                  marginRight: -1.5,
                                }}
                              >
                                <GoldCircle
                                  style={{ position: "absolute" }}
                                  width={20}
                                  height={20}
                                />
                                <GoldPlus style={{ position: "absolute" }} />
                              </Box>
                              <Box
                                sx={{
                                  width: 42,
                                  height: 24,
                                  backgroundColor: "#0096FF",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  p: 1,
                                }}
                              >
                                <Typography
                                  color={"white"}
                                  fontSize={11}
                                  fontWeight={800}
                                >
                                  +{categoryProps.score}
                                </Typography>
                              </Box>
                            </Box> : null}
                        </Box>
                        <Typography>{categoryProps?.description}</Typography>
                        <Box>
                          {categoryProps.score > 0 ? (
                            <Typography fontWeight={800}>
                              {`Improves score by ${categoryProps.score} points in:`}
                            </Typography>
                          ) : null}
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              paddingTop: 1,
                            }}
                          >
                            {categoryProps.category_details.map(
                              (category: RecommendedCategory) => (
                                <Chip
                                  key={category._id}
                                  sx={{
                                    backgroundColor:
                                      steps.find(
                                        (step) =>
                                          step.categoryId ===
                                          category.category_key
                                      )?.categorySecondaryColor ?? "#E0F2DC",
                                    color:
                                      steps.find(
                                        (step) =>
                                          step.categoryId ===
                                          category.category_key
                                      )?.categoryPrimaryColor ?? "#72D65A",
                                  }}
                                  label={category.category_name}
                                />
                              )
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                ))}
            </Box>
          ) : null}
          {/* <Card
       sx={{
         m: 2,
         backgroundColor: "#e1ebf7ff",
         backgroundImage: `url(${ConnectWithHCImg})`,
         backgroundPosition: "right",
         backgroundRepeat: "no-repeat",
         backgroundSize: "contain",
         p: 2.5,
       }}
     >
       <Typography color={"#5A5A5A"}>Connect with your</Typography>
       <Typography color={"#494E9D"} variant="h4" fontSize={18}>
         Health Coach
       </Typography>
       <Typography color={"#6F6F6F"}>
         to better understand your scores<br></br> and recommendations
       </Typography>
       <Button
         fullWidth
         color="primary"
         variant="contained"
         sx={{ marginTop: 1 }}
       >
         Schedule Now
       </Button>
     </Card> */}
          <Box p={2} sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              loading={retakeSurveyLoading}
              onClick={handleRetake}
            >
              Retake Assessment
            </LoadingButton>
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              loading={sendReportLoading}
              onClick={handleSendReport}
            >
              Send Report
            </LoadingButton>
          </Box>
        </Scrollbar>
        <RangeModel open={rangeModelOpen} setOpen={setRangeModelOpen} />
        <InsightsModel
          open={insightsModelOpen}
          setOpen={setInsightsModelOpen}
          recommendations={scoreData.recommendations}
        />
      </Box>
      <LogoutModal open={open} setOpen={setOpen} />
    </React.Fragment>
  );
};

export default ScoreCard;
